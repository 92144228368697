import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { Loadable } from '@core/models/models/loadable.models'
import { DetailsWidgetBase } from '@core/models/base-classes/details-widget.base'
import {
  McpMetricListSetup,
  McpMetricsMetadata,
  McpSpiderChartSetup,
} from '@core/models/dto/metric.dto'
import { mapMetrics, mapSelectedMetric } from '@core/utils/metrics.utils'
import { getStaticItem, smallColorLogoPredicate } from '@core/utils/assets.utils'
import { PlayerPosition } from '@core/enums/player.enums'
import { PlayerSummary } from '@core/models/dto/player.dto'

@Component({
  selector: 'mcp-widget-metrics',
  templateUrl: './widget-metrics.component.html',
  styleUrls: ['./widget-metrics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetMetricsComponent extends DetailsWidgetBase {
  private _metrics: McpMetricsMetadata

  @Input() isPlayer = false
  @Input() playerPosition: string

  @Input() set metrics(_m: Loadable<McpMetricsMetadata>) {
    this.teamLoading = _m.loading
    if (_m && _m.value && _m.value.metrics && _m.value.summary) {
      this._setupChart(_m.value)
    }
  }

  @Output() changePosition = new EventEmitter<void>()
  @Output() metricClick = new EventEmitter()

  chartSetup: McpSpiderChartSetup
  listSetup: McpMetricListSetup
  isGoalkeeper: boolean

  private _setupChart(value: McpMetricsMetadata): void {
    const { chart, list } = mapMetrics(
      value,
      this.isPlayer,
      getStaticItem(this.competition?.statics, smallColorLogoPredicate),
      this.competition?.officialName || '',
    )
    this.chartSetup = chart
    this.listSetup = list
    this._metrics = value
    if (this.isPlayer) {
      const {
        summary: { playerPosition },
      } = value as McpMetricsMetadata<PlayerSummary>
      this.isGoalkeeper = playerPosition === PlayerPosition.Goalkeeper
      this.playerPosition = this.playerPosition || playerPosition
    }
  }

  onMetricClick(metricKey: string): void {
    if (metricKey) {
      this.metricClick.emit(
        mapSelectedMetric(metricKey.trim(), this.chartSetup.chartMetrics, this._metrics.summary),
      )
    }
  }

  changePlayerPosition() {
    if (!this.isGoalkeeper) {
      this.changePosition.emit()
    }
  }
}
