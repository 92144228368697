<mcp-dialog-content-container
  [disabled]="
    ((!selectedSeason || selectedSeason?.id === seasonId) &&
      (!selectedCompetition || selectedCompetition?.id === competitionId) &&
      (!againstToItem || (!!againstToItem && againstToItem?.id === toCompareId)) &&
      (!vsCompetition || isVsCompetition === vsCompetition)) ||
    (!againstToItem && !vsCompetition)
  "
  (apply)="applySelection()"
>
  <div class="mcp-dialog-vs-selector">
    <mcp-season-competition-selector
      [seasons]="seasons$ | async"
      [seasonId]="seasonId"
      [competitionId]="competitionId"
      (competitionChange)="selectedCompetition = $event; searchData()"
      (seasonChange)="selectedSeason = $event; searchData()"
    ></mcp-season-competition-selector>
    <div class="mcp-widget-dialog__block--divider"></div>
    <div class="mcp-dialog-vs-selector__selector">
      <div class="mcp-dialog-vs-selector__selector-header">
        <div class="mcp-dialog-vs-selector__text-search">
          <app-input
            class="mcp-dialog-vs-selector__text-search-input"
            icon="search"
            [ngModel]="dialogConfig?.againstTo === 'player' ? (textSearched$ | async) : null"
            [placeholder]="dialogConfig.searchTextPlaceholder | translate"
            (onKeyUp)="onSearch($event)"
          />
        </div>
        <div class="mcp-dialog-vs-selector__selector-header--divider"></div>
        <div
          class="mcp-widget-dialog__item mcp-dialog-vs-selector__item-vs-competition"
          [class.mcp-widget-dialog__item--active]="vsCompetition === true"
          (click)="vsCompetition = true; againstToItem = null"
        >
          <span class="mcp-widget-dialog__item-content">
            vs
            {{
              aggregationTrans[
                widget?.displayMode?.aggregation || widget?.displayMode?.rightAggregation
              ] | translate
            }}
            {{ 'MTR_COMPETITION' | translate }}
          </span>
        </div>
        <div class="mcp-dialog-vs-selector__selector-header--divider"></div>
      </div>
      <div
        class="mcp-widget-dialog__block mcp-dialog-vs-selector__selector-block"
        *mcuiLoading="loading$ | async"
      >
        @for (result of results$ | async; track result.id) {
          <div
            class="mcp-widget-dialog__item mcp-dialog-vs-selector__item"
            [title]="result?.formattedName || result.name || ''"
            [class.mcp-widget-dialog__item--active]="result.id === againstToItem?.id"
            (click)="againstToItem = result; vsCompetition = false"
            #selectorElement
          >
            @if (dialogConfig?.againstTo === 'team') {
              <img
                class="mcp-dialog-vs-selector__item-image"
                [appDefaultImage]="dialogConfig.imagePlaceholder"
                [srcUrl]="result?.portraitLogo"
                [alt]="result?.name"
              />
              <span
                class="mcp-widget-dialog__item-content mcp-dialog-vs-selector__item-content"
                [innerHTML]="
                  (textSearched$ | async)?.length >= spaceMinCharToFilter
                    ? (result.name | highlight: (textSearched$ | async) : true)
                    : result.name
                "
              ></span>
            } @else {
              <mc-player-list-item [data]="result"></mc-player-list-item>
            }
            <div class="mcp-dialog-vs-selector__selector-header--divider"></div>
          </div>
        } @empty {
          <span
            class="mcp-dialog-vs-selector__no-results"
            *ngIf="(textSearched$ | async)?.length >= spaceMinCharToFilter"
            >{{ 'MTR_COMMON_NO_RESULTS' | translate }}</span
          >
        }
      </div>
    </div>
  </div>
</mcp-dialog-content-container>
<ng-container *ngIf="text$ | async" />
