import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core'

import { DeepEqual, FieldDirection, HeatMapConfig } from '@mediacoach-ui-library/global'

import { HeatMapDefaultOptions, ParseHeatMapData } from './heat-map.constants'
import { SegmentModifier, SegmentOption } from '../segmented-control/segmented-control.models'

@Component({
  selector: 'app-heat-map',
  templateUrl: './heat-map.component.html',
  styleUrls: ['./heat-map.component.scss'],
})
export class HeatMapComponent {
  private _data
  private _options: SegmentOption[] = JSON.parse(JSON.stringify(HeatMapDefaultOptions))

  dataDictionary

  get data() {
    return this._data
  }
  @Input() set data(_data) {
    this._data = _data || {}
    this._setDataDictionary()
  }

  get options() {
    return this._options
  }
  @Input() set options(_options) {
    if (!DeepEqual(this._options, _options)) {
      this._options = _options
      this._setDataDictionary()
    }
  }

  @Input() loading = false
  @Input() title
  @Input() segmentValue
  @Input() heatMapConfig: HeatMapConfig
  @Input() isColored = true
  @Input() arrowDirection = FieldDirection.Right

  @Output() onChange = new EventEmitter<string>()

  SegmentModifier = SegmentModifier

  constructor(private _el: ElementRef) {}

  private _setDataDictionary(options = this.options) {
    this.dataDictionary = options.reduce(
      (obj, { id }) => ({ ...obj, [id]: ParseHeatMapData(this.data[id]) }),
      {},
    )
    this._updateDisableOptions()
  }

  private _updateDisableOptions() {
    this._options = this.options.map((option) => ({ ...option, disabled: !this.data[option.id] }))
    this.segmentValue = (
      this.options.find(({ selected }) => selected) ||
      this.options.find(({ disabled }) => !disabled) ||
      <SegmentOption>{}
    ).id
  }
}
