<div class="mcp-widget-grid-overview-title">
  <app-title [title]="label" [size]="titleSize" extraClasses="c-title--left"></app-title>
  <mc-button
    class="mcp-widget-grid-overview-title--fullscreen"
    icon="launch-light"
    *ngIf="overview?.length > 0"
    [buttonType]="buttonIcon"
    (click)="maximize()"
  ></mc-button>
</div>
<app-loader [loading]="loading || teamLoading">
  <div class="mcp-widget-grid-overview" [attr.data-cy]="'grid-overview'">
    <div
      *ngIf="overview?.length > 0; else notAvailableContent"
      class="mcp-widget-grid-overview__content"
    >
      <ng-container
        *ngTemplateOutlet="
          gridTpl;
          context: {
            columns: compactColumns,
            mode: 'compact',
            footer: stickyFooter
          }
        "
      ></ng-container>
    </div>
  </div>
  <ng-template #notAvailableContent>
    <mcp-unavailable-content [competition]="competition"></mcp-unavailable-content>
  </ng-template>
</app-loader>

<app-dialog
  [(visible)]="maximized"
  [style]="{ bottom: '8px', top: '0' }"
  closeIconColor="dark"
  [title]="label | translate"
  class="mcp-expandable-dialog"
>
  <div class="dialog-header">
    <div class="mcp-widget-grid-overview-buttons">
      <ng-container *ngIf="toggle">
        <ng-container *ngTemplateOutlet="toggle"></ng-container>
      </ng-container>
      <div class="mcp-widget-grid-overview__export">
        <mc-button
          text="{{ 'MTR_COMMON_DOWNLOAD_PDF' | translate }}"
          [isDisabled]="pdfLoader"
          (click)="exportToPdf()"
        >
        </mc-button>
      </div>
    </div>
  </div>
  <div class="dialog-content">
    <app-loader [loading]="pdfLoader" [title]="'MTR_COMMON_DOWNLOAD_PDF_TITLE'">
      <ng-container
        *ngTemplateOutlet="
          gridTpl;
          context: {
            columns: expandedColumns,
            mode: 'expanded',
            footer: stickyFooter
          }
        "
      ></ng-container>
    </app-loader>
  </div>
</app-dialog>

<app-metric-modal #metricModal></app-metric-modal>
<ng-template #gridTpl let-columns="columns" let-mode="mode">
  <mcp-grid
    [sortable]="true"
    [ngClass]="mode"
    [rows]="overview"
    [columns]="columns | nestedSortableKey: metricKey"
    [cellTpl]="cellTpl"
    [headerTpl]="headerTpl"
    [footerTpl]="footerTpl"
    (cellClick)="cellClick.emit({ data: $event, modal: metricModal })"
  >
    <ng-template #headerTpl let-column="column">
      <div class="default-header" mcTooltip="{{ column.tooltipLabel | translate }}">
        {{ column.label || column.key | translate }}
      </div>
    </ng-template>

    <ng-template
      #cellTpl
      let-row="row"
      let-column="column"
      let-isFirstColumn="isFirstColumn"
      let-columnIndex="columnIndex"
    >
      <!--  custom column-->
      <ng-container *ngIf="firstColumBlock">
        <ng-container
          [ngTemplateOutlet]="firstColumBlock"
          [ngTemplateOutletContext]="{
            index: columnIndex,
            isFirstColumn: isFirstColumn,
            row: row,
            mode: mode,
            column: column
          }"
        ></ng-container>
      </ng-container>

      <!-- metric column -->
      <div
        class="default-cell__metric hand-cursor"
        [class.has-icon]="!!column.icon"
        [title]="column.tooltipLabel || column.label || column.key | translate"
        *ngIf="!column.isCustom && ((row[metricKey] || {})[column.key] || {}) as cell"
      >
        <span *ngIf="column.icon" class="player-card" [ngClass]="column.icon"></span>
        {{ cell | metric: true }}
      </div>
    </ng-template>

    <ng-template #footerTpl let-column="column" let-isFirstColumn="isFirstColumn">
      <div
        class="default-footer"
        [class.has-icon]="!!column.icon"
        [class.bolder]="isFirstColumn"
        *ngIf="stickyFooter"
      >
        <span *ngIf="column.icon" class="player-card" [ngClass]="column.icon"></span>
        <ng-container *ngIf="isFirstColumn">{{ totalTitle | translate | uppercase }}</ng-container>

        <ng-container *ngIf="!isFirstColumn && !column.emptyFooter">
          {{
            ((stickyFooter[metricKey] || {})[column.key] || {}).value === 0
              ? 0
              : (((stickyFooter[metricKey] || {})[column.key] || {}).value
                  | number: '1.0-1' : lang) || '-'
          }}{{
            ((stickyFooter[metricKey] || {})[column.key] || {}).value === 0 || null
              ? ''
              : ' ' +
                ((((stickyFooter[metricKey] || {})[column.key] || {}).unit
                  | translate
                  | lowercase) || '')
          }}
        </ng-container>
      </div>
    </ng-template>
  </mcp-grid>
</ng-template>
