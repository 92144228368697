import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '@env'
import { Observable } from 'rxjs'
import {
  PlaylistsResponse,
  TagCodeResponse,
  TagListResponse,
} from '@features/playlist/models/playlist.response.models'
import { TagItem } from '@mediacoach/ui'

@Injectable()
export class PlaylistApi {
  constructor(private readonly _http: HttpClient) {}

  exportPlaylistTags(id: string, matchId: string): Observable<any> {
    return this._http.get<any>(`${environment.API.EXPORT_PLAYLIST_TAGS(id)}?matchId=${matchId}`, {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      responseType: 'text',
      headers: { Accept: 'application/xml' },
    })
  }

  deleteTag(id, tagId) {
    return this._http.delete(`${environment.API.DELETE_PLAYLIST_TAG(id, tagId)}`)
  }

  getPlaylists(matchId: string) {
    return this._http.get<PlaylistsResponse>(
      `${environment.API.GET_PLAYLISTS}?matchId=${matchId}&offset=0&limit=1000000`,
    )
  }

  getPlaylistTags(id, matchId: string, filters?: any) {
    return this._http.get<TagListResponse>(
      `${environment.API.GET_PLAYLIST_TAGS(id)}?matchId=${matchId}${filters ? `&${filters}` : ''}`,
    )
  }

  getPlaylistDimensions(id, matchId: string): Observable<string[]> {
    return this._http.get<string[]>(
      `${environment.API.GET_PLAYLIST_DIMENSIONS(id)}?matchId=${matchId}`,
    )
  }

  getPlaylistCodes(id, matchId: string): Observable<TagCodeResponse[]> {
    return this._http.get<TagCodeResponse[]>(
      `${environment.API.GET_PLAYLIST_CODES(id)}?matchId=${matchId}`,
    )
  }

  updateTag(matchId: string, tagId: string, tagItem: TagItem) {
    return this._http.put(environment.API.UPDATE_TAG(matchId, tagId), tagItem)
  }
}
