export enum AnalyticsAssetType {
  Report = 'REPORT',
  Video = 'VIDEO',
}

export enum AnalyticsExportType {
  Team = 'TeamSquadOverview',
  Player = 'PlayerMatchesOverview',
  Lineup = 'MatchLineup',
}

export enum AnalyticsAssetContentType {
  AccumulatedTeam = 'ReportAccumulatedTeam',
  AccumulatedPlayer = 'ReportAccumulatedPlayer',
}

export enum AnalyticsAssetQuality {
  Completed = 'Completed',
  Reviewed = 'Reviewed',
}
export enum AnalyticsAssetExtension {
  XLSX = 'XLSX',
  XML = 'XML',
  CSV = 'CSV',
  MP4 = 'MP4',
  PDF = 'PDF',
}
