import { ChangeDetectionStrategy, Component, Optional } from '@angular/core'
import { DefaultProjectorFn, MemoizedSelector, Store } from '@ngrx/store'
import { FilterType } from '@features/playlist/enums/playlist.enums'
import { TranslateService } from '@ngx-translate/core'
import { filter, map, take, tap } from 'rxjs/operators'
import { FilterItem } from '@features/playlist/models/playlist.models'
import { PlaylistService } from '@core/services/playlist.service'
import { ChipItemChangeEvent, ChipItemClickEvent, DialogConfig } from '@mediacoach/ui'
import { Observable } from 'rxjs'
import { DEFAULT_ALL_FILTERS } from '@features/playlist/constants/playlist.constants'
import { hasFiltersActive } from '@features/playlist/utils/playlist.utils'
import { MergedTokens } from '@core/state/models/merged-tokens.model'
import {
  applyFilters,
  updateFilterValues,
} from '@core/state/actions/stream-playlist.merged-actions'
import {
  selectCurrentMatchFilterValues,
  selectFilterConfigByType,
  selectFilterValuesByType,
  selectHasFiltersActive,
} from '@core/state/selectors/stream-playlist.merged-selectors'

@Component({
  selector: 'mcp-playlist-filters-modal',
  templateUrl: './playlist-filters-modal.component.html',
  styleUrls: ['./playlist-filters-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaylistFiltersModalComponent {
  private readonly _identifier: MergedTokens
  private readonly _selector: MemoizedSelector<object, any, DefaultProjectorFn<any>>
  private _translateItemLabel = (items: FilterItem[]) =>
    items.map((item) => ({
      ...item,
      label: item?.label ? this.translateService.instant(item.label) : null,
    }))

  allConfig$: Observable<any>
  otherConfig$: Observable<any>
  eventConfig$: Observable<FilterItem[]>
  teamConfig$: Observable<FilterItem[]>
  playerConfig$: Observable<FilterItem[]>
  allValues$: Observable<any>
  otherValues$: Observable<any>
  eventValues$: Observable<any>
  teamValues$: Observable<any>
  playerValues$: Observable<any>
  filterValues$: Observable<any>
  previousFilterValues$: Observable<any>
  hasFiltersActive$: Observable<boolean>

  readonly modalFilterClosed$: Observable<any> =
    this.playlistService.filtersDialogRef?.onClose.pipe(
      filter((data) => data?.closed),
      tap(() => {
        this._store.dispatch(
          updateFilterValues(this._identifier)({ filterValues: this.previousFilterValues }),
        )
      }),
    )

  readonly filterType = FilterType

  previousFilterValues: any

  data: any

  constructor(
    private readonly _store: Store,
    private readonly translateService: TranslateService,
    private readonly playlistService: PlaylistService,
    @Optional() private dialogConfig: DialogConfig,
  ) {
    this.data = dialogConfig.data
    this._selector = dialogConfig.data.selector
    this._identifier = dialogConfig.data.identifier
    this._initializeSubs()
  }

  private _initializeSubs() {
    this.allConfig$ = this._store
      .select(selectFilterConfigByType(FilterType.All, this._selector))
      .pipe(map(this._translateItemLabel))
    this.otherConfig$ = this._store
      .select(selectFilterConfigByType(FilterType.Other, this._selector))
      .pipe(map(this._translateItemLabel))
    this.eventConfig$ = this._store.select(
      selectFilterConfigByType(FilterType.Event, this._selector),
    )
    this.teamConfig$ = this._store.select(selectFilterConfigByType(FilterType.Team, this._selector))
    this.playerConfig$ = this._store.select(
      selectFilterConfigByType(FilterType.Player, this._selector),
    )
    this.allValues$ = this._store.select(selectFilterValuesByType(FilterType.All, this._selector))
    this.otherValues$ = this._store.select(
      selectFilterValuesByType(FilterType.Other, this._selector),
    )
    this.eventValues$ = this._store.select(
      selectFilterValuesByType(FilterType.Event, this._selector),
    )
    this.teamValues$ = this._store.select(selectFilterValuesByType(FilterType.Team, this._selector))
    this.playerValues$ = this._store.select(
      selectFilterValuesByType(FilterType.Player, this._selector),
    )
    this.filterValues$ = this._store.select(selectCurrentMatchFilterValues(this._selector))
    this.previousFilterValues$ = this.filterValues$.pipe(
      take(1),
      tap((filterValues) => (this.previousFilterValues = filterValues)),
    )
    this.hasFiltersActive$ = this._store.select(selectHasFiltersActive(this._selector))
  }

  private _checkHasFilterActive(filterValues: any) {
    if (hasFiltersActive(filterValues)) {
      this._store.dispatch(updateFilterValues(this._identifier)({ filterValues }))
    } else {
      this._setDefaultFilters()
    }
  }

  private _setDefaultFilters() {
    this._store.dispatch(
      updateFilterValues(this._identifier)({
        filterValues: {
          [FilterType.All]: [DEFAULT_ALL_FILTERS[0]],
        },
      }),
    )
  }

  private _toggleAllItems(items: any[], item: any) {
    const values = [...(items || [])]
    const index = items.findIndex((filterItem) => filterItem.value === item?.value)
    if (index !== -1) {
      values.splice(index, 1)
    } else {
      values.push(item)
    }
    return values
  }

  itemAllClick({ item }: ChipItemClickEvent, currentFilters: any) {
    if (item?.id === FilterType.All) {
      this._setDefaultFilters()
    } else {
      const filterValues = { ...(currentFilters || {}) }
      filterValues[FilterType.All] = (filterValues[FilterType.All] || []).filter(
        (filterItem: any) => filterItem.id !== FilterType.All,
      )
      filterValues[FilterType.All] = this._toggleAllItems(filterValues[FilterType.All], item)
      this._checkHasFilterActive(filterValues)
    }
  }

  selectionChange(filterType: FilterType, { value }: ChipItemChangeEvent, currentFilters: any) {
    const filterValues = { ...(currentFilters || {}) }
    filterValues[filterType] = value
    filterValues[FilterType.All] = (filterValues[FilterType.All] || []).filter(
      (item: any) => item.id !== FilterType.All,
    )
    this._checkHasFilterActive(filterValues)
  }

  applyFilters() {
    this.playlistService.closeFiltersDialog()
    this._store.dispatch(applyFilters(this._identifier)())
  }
}
