import { createFeatureSelector, createSelector, select } from '@ngrx/store'
import { SPACE_FEATURE_TOKEN } from '../../tokens/space.tokens'
import { SpaceState } from '../models/space.state'
import { searchTeamsByText } from '@core/space/utils/space-dialog.utils'
import { ApiTeam } from '@core/models/models/team.models'
import { pipe } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { Widget, WidgetSpaceType } from '@widgets/models/widget.models'
import { getSeasons } from '@core/state/selectors/seasons.selectors'
import { getMergedRouteParams } from '@core/router/state/selectors/router.selectors'
import { resolveAggregation } from '@core/space/utils/space-metrics.utils'
import { WIDGET_SPACE_TYPE_PERMISSION_MAP } from '@widgets/constants/widget-permissions-map.constants'
import { selectWidgetPermissions } from '@core/state/selectors/user.selectors'

export const selectSpaceFeature = createFeatureSelector<SpaceState>(SPACE_FEATURE_TOKEN)

export const selectSpaceHeaderLoader = createSelector(
  selectSpaceFeature,
  (state) => state.headerLoading,
)

export const selectSpaceTeamMetricDefinition = createSelector(
  selectSpaceFeature,
  (state) => state.teamMetricDefinition,
)

export const selectSpacePlayerMetricDefinition = createSelector(
  selectSpaceFeature,
  (state) => state.playerMetricDefinition,
)

export const selectSpaceGoalkeeperMetricDefinition = createSelector(
  selectSpaceFeature,
  (state) => state.goalkeeperMetricDefinition,
)

export const selectSpaceDialogSearch = createSelector(
  selectSpaceFeature,
  (state) => state.dialogSearch,
)

export const selectSpaceDialogSearchText = createSelector(
  selectSpaceDialogSearch,
  (state) => state.text,
)

export const selectSpaceDialogSearchLoading = createSelector(
  selectSpaceDialogSearch,
  (state) => state.loading,
)

export const selectSpaceDialogSearchScope = createSelector(
  selectSpaceDialogSearch,
  (state) => state.scope,
)

export const selectSpaceDialogSearchTextWithScope = createSelector({
  text: selectSpaceDialogSearchText,
  scope: selectSpaceDialogSearchScope,
})

export const selectSpaceDialogSearchTextPlayer = () =>
  pipe(
    select(selectSpaceDialogSearchTextWithScope),
    filter(({ scope }) => scope === 'player'),
    map(({ text }) => text),
  )

export const selectSpaceDialogSearchResults = createSelector(
  selectSpaceDialogSearch,
  (state) => state.results,
)

export const selectSpaceDialogSearchTeams = createSelector(
  selectSpaceDialogSearchText,
  selectSpaceDialogSearchResults,
  (text, results) => searchTeamsByText(results as ApiTeam[], text),
)

export const selectSpaceWidgetSeasonAndAggregationPills = (widget: Widget) =>
  createSelector(getMergedRouteParams, getSeasons, (params, seasons) => {
    let pills = []
    if (params && seasons && widget) {
      const season = seasons.find((s) => s.id === params['seasonId'])
      pills = [resolveAggregation(widget)]
      if (season) {
        pills.unshift(season.name.replace(/ - \d{2}/, '/'))
      }
    }

    return pills || []
  })

export const selectSpaceWidgetSeasonPills = (_: Widget) =>
  createSelector(getMergedRouteParams, getSeasons, (params, seasons) => {
    const pills = []
    if (params && seasons) {
      const season = seasons.find((s) => s.id === params['seasonId'])
      if (season) {
        pills.push(season.name.replace(/ - \d{2}/, '/'))
      }
    }

    return pills || []
  })

export const selectSpaceWidgetAggregationPill = (widget: Widget) =>
  createSelector(getSeasons, (_) => {
    if (widget) {
      return [resolveAggregation(widget)]
    }
    return []
  })

export const selectWidgetPermissionsBySpaceType = (spaceType: WidgetSpaceType) =>
  createSelector(selectWidgetPermissions, (permissions) => {
    if (permissions?.length) {
      return WIDGET_SPACE_TYPE_PERMISSION_MAP[spaceType].filter((p) => permissions.includes(p))
    }
  })

export const selectSpaceDemarcations = createSelector(selectSpaceFeature, (state) => {
  if (state.demarcations?.length) {
    return state.demarcations.map((d) => ({
      ...d,
      positions: d.positions.map((p) => ({ label: p.translationKey, value: p.key })),
    }))
  }
})
