<div class="mcp-playlist-item-info">
  <div class="mcp-playlist-item-info__content">
    <div class="mcp-playlist-item-info__content-index">
      {{ item?.index }}
    </div>
    <div class="mcp-playlist-item-info__content-tag">
      <div class="mcp-playlist-item-info__content-tag-image">
        <img
          class="mcp-playlist-item-info__content-tag-image-logo"
          [mcuiDefaultImage]="placeholderImage"
          [srcUrl]="item?.image"
          [title]="item?.title"
        />
      </div>
      <div class="mcp-playlist-item-info__content-tag-info">
        <div class="mcp-playlist-item-info__content-tag-info__container">
          <div class="mcp-playlist-item-info__content-tag-info__entity" *ngIf="isEntity">
            <div class="mcp-playlist-item-info__label mcp-playlist-item-info__label--subtitle">
              {{ item?.subtitle || ('MTR_TITLE_TEAM' | translate) }}
            </div>
            <div class="mcp-playlist-item-info__value mcp-playlist-item-info__value--title">
              {{ item?.title }}
            </div>
          </div>
          <div class="mcp-playlist-item-info__content-tag-info__event">
            <div class="mcp-playlist-item-info__label mcp-playlist-item-info__label--event">
              {{ 'MTR_TITLE_EVENT' | translate }}
            </div>
            <div class="mcp-playlist-item-info__value mcp-playlist-item-info__value--event">
              {{ item?.eventName }}
            </div>
          </div>
          <div class="mcp-playlist-item-info__content-tag-info__time">
            <div class="mcp-playlist-item-info__label mcp-playlist-item-info__label--time">
              {{ 'MTR_TITLE_TIME' | translate }}
            </div>
            <div class="mcp-playlist-item-info__value mcp-playlist-item-info__value--time">
              {{ item?.timeDescription }}
            </div>
          </div>
          <div class="mcp-playlist-item-info__content-tag-info__owner" *ngIf="item?.owner">
            <span class="mcp-playlist-item-info__content-tag-info__owner-send-by">{{
              'MTR_TITLE_OWNER_SEND_BY' | translate
            }}</span>
            <span class="mcp-playlist-item-info__content-tag-info__owner-name"
              >{{ item?.owner?.name }} {{ item?.owner?.surname }}</span
            >
            <mcui-avatar
              class="mcp-playlist-item-info__content-tag-info__owner-avatar"
              [backgroundColor]="item?.owner?.color"
              [imageSource]="item?.owner?.avatarPath"
              title="{{ item?.owner?.name }} {{ item?.owner?.surname }}"
              [text]="item?.owner?.name | firstCharacter: item?.owner?.surname"
            ></mcui-avatar>
          </div>
        </div>
        <div
          class="mcp-playlist-item-info__content-tag-info__comment"
          *ngIf="item && item['comment']"
        >
          <span class="mcp-playlist-item-info__content-tag-info__comment-title">
            {{ 'MTR_TITLE_COMMENTS' | translate }}
          </span>
          {{ item && item['comment'] }}
        </div>
      </div>
    </div>
  </div>
  <div class="mcp-playlist-item-info__actions">
    <mcui-icon
      (click)="deleteTagItem.emit(item)"
      class="mcp-playlist-item-info__actions-icon"
      svgIcon="trash"
    ></mcui-icon>
  </div>
</div>
