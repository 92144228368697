import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import 'hammerjs'
import * as moment from 'moment'

import { AppModule } from './app/app.module'
import { environment } from '@env'
import { appendBuildInfo } from '@core/utils/application.utils'
import { initSmartLook } from '@core/analytics/utils/analytics.utils'

if (environment.production) {
  enableProdMode()
  appendBuildInfo(
    environment.appVersion,
    environment.appBuild,
    moment(environment.appRelease, 'DDMMYYYY.hhmm').format('DD-MM-YYYY'),
  )
}

if (environment.enableSmartLook) {
  initSmartLook(environment.SMART_LOOK_API_KEY)
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => console.log('Bootstrap success'))
  .catch((err) => console.error(err))
