import { EnvType, UserProfileType } from '@mediacoach-ui-library/global'

import { GetEnvironment } from './environment.constants'
import { ReportRoute } from '@features/reports/enums/reports.enum'

/* eslint-disable @typescript-eslint/naming-convention, max-len */
export const POWER_BI_CONFIG_DV = {
  POWER_BI_DEFAULT_LANG: 'es',
  POWER_BI_WORKSPACE: 'b4ce5693-889b-42ed-8865-e24e98f57816',
  POWER_BI_MONITORING: {
    '2024-2025': [
      {
        route: ReportRoute.teams,
        es: '75915dbd-0793-4000-8c28-cbe65d5088c9',
        en: 'f50a2d6e-6833-45bd-a417-0d8f32e9d696',
      },
      {
        route: ReportRoute.players,
        es: 'c04e76c6-ac9c-4717-a6a4-81fc57f998be',
        en: '3714897d-1d04-4614-b3bd-d66c41bcd141',
      },
      {
        route: ReportRoute.goalkeepers,
        es: 'e716b586-c1f2-46db-85c2-ff3f8e2301b0',
        en: 'e716b586-c1f2-46db-85c2-ff3f8e2301b0',
      },
      {
        route: ReportRoute.styleEfficiency,
        es: '3f837812-3c75-4ad4-a5d4-d7964598118e',
        en: 'cb3d5701-6e85-4cf3-8028-a0cc0ca979a1',
      },
      {
        route: ReportRoute.playerStats,
        es: 'd75407dd-855c-4186-9a17-b3c8acf7384d',
        en: '3df23e78-2bc2-49cb-8171-c51cdbe3e933',
      },
      {
        route: ReportRoute.accumulatedTeams,
        es: '82b6f6b5-c0bd-40b6-8afd-04a29e3a4a73',
        en: '6daab7b5-913e-45bf-8a60-5c2ff5f3c109',
      },
      {
        route: ReportRoute.physicalPerformance,
        es: '4e119ee1-a63d-4766-81c7-9534ca15bbe4',
        en: '40f1ba80-055c-4d92-b685-696af0d2ddb0',
      },
      {
        route: ReportRoute.physicalIntervals,
        es: '700a48c7-211d-4108-9772-add9be3fbe57',
        en: '1acd1ec9-8b03-49dd-83a2-24307dcf7e0f',
      },
      {
        route: ReportRoute.referees,
        es: 'c740633b-08da-4b34-9b3d-233433ff382e',
        en: '8f4b4a29-f62c-4811-a1ad-89fe7a29dff9',
      },
      {
        route: ReportRoute.gameInterruptions,
        es: '18e1b660-8c5b-41c1-89c7-aeaa1e856fa2',
        en: '8103daba-1a83-4722-b51c-8a06d901276e',
      },
      {
        route: ReportRoute.compEvolutionEffectiveTime,
        es: 'c740633b-08da-4b34-9b3d-233433ff382e', //FIXME: Mock ID
        en: '8f4b4a29-f62c-4811-a1ad-89fe7a29dff9', //FIXME: Mock ID
      },
      {
        route: ReportRoute.finishing,
        es: '1d87c572-5e0a-40b6-9523-212ec9a5e4f5',
        en: '8bfd8c99-1ab3-42f1-bfe6-76ac51890a99',
      },
    ],

    '2023-2024': [
      {
        route: ReportRoute.teams,
        es: 'd851920a-bebb-4600-b5df-32857b53f5ae',
      },
      {
        route: ReportRoute.players,
        es: 'ca891a60-3ef9-40b3-a831-ba66bfd309f8',
      },
      {
        route: ReportRoute.goalkeepers,
        es: '28941b75-47de-4639-ab01-5abb50e18c94',
      },
      {
        route: ReportRoute.styleEfficiency,
        es: '659bfa61-858e-4ccc-9f83-b7d15b3ed825',
      },
      {
        route: ReportRoute.playerStats,
        es: 'ec1a1472-e53c-4549-a02a-782598419514',
      },
      {
        route: ReportRoute.accumulatedTeams,
        es: '5179c859-f5d0-4fd1-8c98-146b591cb96f',
      },
      {
        route: ReportRoute.physicalPerformance,
        es: '8f4d3eb1-74a4-4cd1-be1d-700872b87033',
      },
      {
        route: ReportRoute.physicalIntervals,
        es: '40f63ded-7a61-454c-ab51-f7f240dec4b0',
      },
      {
        route: ReportRoute.referees,
        es: 'c9c729e0-8d5d-435e-b51a-796616fa22b7',
      },
      {
        route: ReportRoute.gameInterruptions,
        es: '7da3856c-4776-454f-89ad-c871c371df5f',
      },
      {
        route: ReportRoute.compEvolutionEffectiveTime,
        es: '5a85e167-bef0-4bb6-b74b-4d1e998bf648', //FIXME: Mock ID
      },
      {
        route: ReportRoute.finishing,
        es: 'bda1ff9f-7442-4857-a352-29be4ff7990e',
      },
    ],
    '2022-2023': [
      {
        route: ReportRoute.teams,
        es: '02128f96-37e9-465d-a9d2-23d29f887d74',
      },
      {
        route: ReportRoute.players,
        es: '31e476b2-b3f2-44f5-bfca-5914d64f739e',
      },
      {
        route: ReportRoute.goalkeepers,
        es: '673f0d11-cd14-4e78-8450-a3465d5eff8a',
      },
      {
        route: ReportRoute.styleEfficiency,
        es: '6052d0bd-ab9f-4bf7-ac0f-67454276725e',
      },
      {
        route: ReportRoute.playerStats,
        es: '05d6de6b-3066-428a-9c0f-56816fb851b0',
      },
      {
        route: ReportRoute.accumulatedTeams,
        es: '4edbe22c-bcc6-40e6-af7b-722e08214bfc',
      },
      {
        route: ReportRoute.physicalPerformance,
        es: 'c31ed84d-e279-403d-8cb5-180f236bfe5a',
      },
      {
        route: ReportRoute.physicalIntervals,
        es: '8e7da930-132f-40c9-bdca-d22bdf897fd1',
      },
      {
        route: ReportRoute.referees,
        es: '5a85e167-bef0-4bb6-b74b-4d1e998bf648',
      },
      {
        route: ReportRoute.gameInterruptions,
        es: 'ad03ef8c-c80d-4dd5-94cd-eb3d55788c52',
      },
      {
        route: ReportRoute.compEvolutionEffectiveTime,
        es: '5a85e167-bef0-4bb6-b74b-4d1e998bf648', //FIXME: Mock ID
      },
    ],
    '2021-2022': [
      {
        route: ReportRoute.teams,
        es: '4d2159a8-e4cf-4e47-9705-37c037a12501',
      },
      {
        route: ReportRoute.players,
        es: 'c57b3a16-36ae-4322-bd06-0e8431dacfab',
      },
      {
        route: ReportRoute.goalkeepers,
        es: 'ddb16757-c17f-452f-9ee1-3048610146a9',
      },
      {
        route: ReportRoute.styleEfficiency,
        es: '93f1a5cb-a335-412b-a3f9-644426ac93a5',
      },
      {
        route: ReportRoute.playerStats,
        es: 'a3191ddf-a22d-4540-845b-dee3c53ccc41',
      },
      {
        route: ReportRoute.accumulatedTeams,
        es: '6c7e09e0-4cd3-4a3f-8a32-2e29d36dca4f',
      },
      {
        route: ReportRoute.physicalPerformance,
        es: 'fd309c23-d196-4171-8cfa-144344e6bc92',
      },
    ],
    '2020-2021': [
      {
        route: ReportRoute.teams,
        es: 'e639314d-0fc2-4b86-80f6-e258fab621c3',
      },
      {
        route: ReportRoute.players,
        es: '987fdd0e-4c26-4681-afc8-661c75d1c1c0',
      },
      {
        route: ReportRoute.goalkeepers,
        es: 'e5e0fb95-9fd9-441a-b130-f5d8fb0335e9',
      },
      {
        route: ReportRoute.styleEfficiency,
        es: 'd0aa0a9a-345d-4e6d-81ad-5b8923a1497d',
      },
      {
        route: ReportRoute.playerStats,
        es: 'c300e497-bbed-4239-b48c-bdb9947bb9f9',
      },
      {
        route: ReportRoute.accumulatedTeams,
        es: '33e73608-08dd-4b84-8f96-0f330915bb7d',
      },
      {
        route: ReportRoute.physicalPerformance,
        es: '556c574c-bec3-4786-a6d0-31516c962545',
      },
    ],
    '2019-2020': [
      {
        route: ReportRoute.teams,
        es: '30f8ffef-e399-4501-9e4c-045d365767f0',
      },
      {
        route: ReportRoute.players,
        es: '8c979d68-f6bc-4f38-bea8-0c14c42736a6',
      },
      {
        route: ReportRoute.goalkeepers,
        es: 'e79b6d92-dbaa-4e6c-bb6d-30d404451eba',
      },
      {
        route: ReportRoute.styleEfficiency,
        es: 'd79fbb79-18c2-404b-89ef-256ffa972670',
      },
      {
        route: ReportRoute.playerStats,
        es: 'e52b213c-c067-4183-8c4a-68d80489b748',
      },
      {
        route: ReportRoute.accumulatedTeams,
        es: 'a230f355-c219-468c-ac98-7f163c6511a7',
      },
      {
        route: ReportRoute.physicalPerformance,
        es: '7cd4d315-2b44-4516-8c27-bc030d16da02',
      },
    ],
    '2018-2019': [
      {
        route: ReportRoute.teams,
        es: 'c45efeed-8913-4bcf-9ef2-3f409f962f76',
      },
      {
        route: ReportRoute.players,
        es: 'fc4bea6f-5522-47f1-bab8-6cea9d490d2e',
      },
    ],
  },
  POWER_BI_METRICS_LAB: {
    '2024-2025': [
      {
        route: ReportRoute.teamsMl,
        es: '5e877659-a0f9-4caa-abf6-7bc2b40d9723',
        en: '47611879-b8ce-4866-9f3f-27c8678e3b04',
      },
      {
        route: ReportRoute.playersMl,
        es: '19dfbe74-41b4-407b-b48e-b0c3b3d2226f',
        en: 'f8e564f8-e7b9-4385-ae7a-b69d048a748c',
      },
      {
        route: ReportRoute.playersMLPhysical,
        es: 'a3da4285-deb9-489f-8184-ac55781dc784',
        en: '2e30c53e-7f9c-49e0-94ac-6e3d0095c3b1',
      },
      {
        route: ReportRoute.wcsMl,
        es: '562861b4-97de-4aaf-b79a-fa5d2fa1ef63',
        en: '47417e21-ab76-4cd1-a016-f7db638db4ad',
      },
    ],

    '2023-2024': [
      {
        route: ReportRoute.teamsMl,
        es: '0333f79c-03b3-4ec7-8b29-8bdc0bfcd326',
      },
      {
        route: ReportRoute.playersMl,
        es: '8ccadf17-6e53-4c7d-9c2b-a11df8dfab6c',
      },
      {
        route: ReportRoute.playersMLPhysical,
        es: 'de7670c7-ad85-44ce-8ecf-ede1db03af51',
      },
      {
        route: ReportRoute.wcsMl,
        es: '252a99f3-98a7-47a2-ac7a-7b18905ae766',
      },
    ],
    '2022-2023': [
      {
        route: ReportRoute.teamsMl,
        es: 'c1f89825-a1e5-4d38-906a-4d063f946296',
      },
      {
        route: ReportRoute.playersMl,
        es: '74c0de7c-492d-44d6-a404-4f49594f16f0',
      },
      {
        route: ReportRoute.playersMLPhysical,
        es: '9bf3d60b-56a8-4025-ab81-1a997bd6a077',
      },
      {
        route: ReportRoute.wcsMl,
        es: 'a2cdefc0-ef0c-4e16-abb8-7c498c6d37b2',
      },
    ],
    '2021-2022': [
      {
        route: ReportRoute.teamsMl,
        es: '229f7fe4-3b22-465d-b715-8d57df67925e',
      },
      {
        route: ReportRoute.playersMl,
        es: '1648708c-dd89-4c53-a1b3-484aabdb6b19',
      },
      {
        route: ReportRoute.wcsMl,
        es: '53ff9082-9d36-4302-bf47-651899ded6dd',
      },
    ],
  },
}
/* eslint-enable @typescript-eslint/naming-convention, max-len */

export const environment = {
  ...GetEnvironment(EnvType.Dv),
  production: false,
  PROFILE_TYPE_BLACKLIST: [UserProfileType.LiveProUserProfile],
  ...POWER_BI_CONFIG_DV,
}
