import { Injectable } from '@angular/core'
import domtoimage from 'dom-to-image'
import { jsPDF } from 'jspdf'
import { cloneNode } from '@core/utils/dom.utils'
import { getUserAgent } from '@core/utils/window.utils'
import { AnalyticsEvent } from '@core/analytics/enums/gtag-events.enum'
import { AnalyticsParam } from '@core/analytics/enums/gtag-params.enum'
import { AnalyticsCategory } from '@core/analytics/enums/gtag-categories.enum'
import { Store } from '@ngrx/store'
import { analyticsTrackEvent } from '@core/analytics/state/actions/analytics.actions'
import { AnalyticsExportType } from '@core/analytics/enums/gtag-assets.enum'

export type PDFOrientation = 'l' | 'p'

export interface PDFSettings {
  background: string
  width: number
  height: number
  style: any
  cacheBust: boolean
  orientation: PDFOrientation
}

@Injectable()
export class PdfService {
  private readonly _allowImages: boolean = ['safari', 'firefox'].includes(getUserAgent())
  private readonly _defaultFilename = `MCPortal_${new Date().getTime()}`

  constructor(private readonly _store: Store) {}

  private async _removeImages(element: HTMLElement): Promise<HTMLElement> {
    const node = (await cloneNode(element, undefined, true)) as HTMLElement
    const imgList = node.querySelectorAll('img')
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < imgList.length; i++) {
      imgList[i].parentNode.removeChild(imgList[i])
    }
    return node
  }

  private _convertDomToPNG(
    element: HTMLElement,
    filename: string,
    options: PDFSettings,
  ): Promise<void> {
    return (this._allowImages ? this._removeImages(element) : Promise.resolve(element))
      .then((n) => domtoimage.toPng(n, options))
      .then((imgData) => {
        const doc = new jsPDF(options.orientation, 'px', [options.width + 16, options.height + 16])
        doc.addImage(imgData, 'PNG', 8, 8, options.width, options.height)
        doc.save(filename.toLowerCase())
      })
      .catch((error) => console.error(error))
  }

  private _sendAnalytics(filename: string, exportType: AnalyticsExportType) {
    this._store.dispatch(
      analyticsTrackEvent({
        eventName: AnalyticsEvent.downloadFile,
        eventParams: {
          [AnalyticsParam.category]: AnalyticsCategory.downloads,
          [AnalyticsParam.fileName]: `${filename}.pdf`,
          [AnalyticsParam.exportType]: exportType,
        },
      }),
    )
  }

  exportAsPDF(
    selector: string,
    imagePlaceholder: string,
    orientation: PDFOrientation,
    filename: string = this._defaultFilename,
    exportType: AnalyticsExportType,
    style = {},
  ): Promise<void> {
    const _el: HTMLElement = document.querySelector(selector)
    if (_el) {
      this._sendAnalytics(filename, exportType)
      return this._convertDomToPNG(_el, filename, {
        background: 'white',
        width: _el.scrollWidth,
        height: _el.scrollHeight,
        style,
        cacheBust: true,
        imagePlaceholder,
        orientation,
      } as PDFSettings)
    }
    return Promise.reject('No element found')
  }
}
