import {
  FilterByMinutesConfig,
  WidgetAggregation,
  WidgetAggregationOption,
  WidgetQueryMetricType,
} from '@widgets/models/widget.models'
import { SortIconConfig } from '@mediacoach/ui'

export const WIDGET_AGGREGATIONS: WidgetAggregationOption[] = [
  {
    label: 'MTR_COMMON_ACCUMULATED',
    metricType: 'Accumulate',
    aggregation: 'accumulatedValue',
  },
  {
    label: 'MTR_COMMON_AVERAGE',
    metricType: 'Average',
    aggregation: 'averageValue',
  },
]

export const WIDGET_AGGREGATION_I18N_MAP: Record<WidgetAggregation, string> = {
  accumulatedValue: 'MTR_COMMON_ACCUMULATED',
  averageValue: 'MTR_COMMON_AVERAGE',
}

export const WIDGET_AGGREGATION_METRIC_TYPE_MAP: Record<WidgetAggregation, WidgetQueryMetricType> =
  {
    accumulatedValue: 'Accumulate',
    averageValue: 'Average',
  }

export const SORT_ICON_CONFIG: SortIconConfig = {
  desc: 'caret-down-rounded',
  asc: 'caret-up-rounded',
  default: 'caret-dual-rounded',
}

export const FILTER_BY_MINUTES_CONFIG: FilterByMinutesConfig = {
  initialValue: 1,
  min: 1,
  max: 100,
  step: 1,
  fieldPlayerThreshold: 43,
  goalkeeperThreshold: 31,
}
