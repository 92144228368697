import { getSeasonAndCompetitionById } from '../../utils/season.utils'
import { Widget, WidgetQuery, WidgetQueryCompare } from '@widgets/models/widget.models'
import { Competition, Season } from '../../models/dto/season.dto'

export const getWidgetCompareQuery = (query: WidgetQuery): WidgetQueryCompare =>
  (query?.compare && Array.isArray(query?.compare)
    ? query?.compare[0]
    : query?.compare) as WidgetQueryCompare

export const getCurrentWidgetCompetition = (
  widget: Widget,
  competition: Competition,
  seasons: Season[],
) => {
  const compare = getWidgetCompareQuery(widget?.query)
  const seasonId = compare?.seasonId
  const competitionId = compare?.competitionId
  return seasonId && competitionId
    ? getSeasonAndCompetitionById(seasonId, competitionId, seasons)?.competition
    : competition
}
