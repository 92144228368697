<div class="mcp-playlist-filters-dialog">
  <div
    class="mcp-playlist-filters-dialog__content"
    *ngIf="{
      allItems: allConfig$ | async,
      otherItems: otherConfig$ | async,
      eventItems: eventConfig$ | async,
      teamItems: teamConfig$ | async,
      playerItems: playerConfig$ | async,
      currentFilterValues: filterValues$ | async,
      allValues: allValues$ | async,
      otherValues: otherValues$ | async,
      eventValues: eventValues$ | async,
      teamValues: teamValues$ | async,
      playerValues: playerValues$ | async,
    } as data"
  >
    <mcui-chips
      [items]="data?.allItems"
      multiple="true"
      dataKey="value"
      [ngModel]="data?.allValues"
      (itemClick)="itemAllClick($event, data?.currentFilterValues)"
    >
    </mcui-chips>
    <mcui-chips
      *ngIf="data?.teamItems?.length"
      [title]="'MTR_PLAYLIST_FILTER_TEAM' | translate"
      dataKey="value"
      multiple="true"
      [ngModel]="data?.teamValues"
      [items]="data?.teamItems"
      (selectionChange)="selectionChange(filterType.Team, $event, data?.currentFilterValues)"
    >
    </mcui-chips>
    <mcui-chips
      *ngIf="data?.eventItems?.length"
      [title]="'MTR_PLAYLIST_FILTER_EVENT' | translate"
      dataKey="value"
      multiple="true"
      [ngModel]="data?.eventValues"
      [items]="data?.eventItems"
      (selectionChange)="selectionChange(filterType.Event, $event, data?.currentFilterValues)"
    >
    </mcui-chips>
    <mcui-chips
      *ngIf="data?.playerItems?.length"
      [title]="'MTR_PLAYLIST_FILTER_PLAYER' | translate"
      dataKey="value"
      multiple="true"
      [ngModel]="data?.playerValues"
      [items]="data?.playerItems"
      (selectionChange)="selectionChange(filterType.Player, $event, data?.currentFilterValues)"
    >
    </mcui-chips>
    <mcui-chips
      [title]="'MTR_PLAYLIST_FILTER_OTHER' | translate"
      dataKey="value"
      multiple="true"
      [ngModel]="data?.otherValues"
      [items]="data?.otherItems"
      (selectionChange)="selectionChange(filterType.Other, $event, data?.currentFilterValues)"
    >
    </mcui-chips>
  </div>
  <div class="mcp-playlist-filters-dialog__footer">
    <button
      class="mcp-playlist-filters-dialog__footer-button button-primary"
      (click)="applyFilters()"
    >
      {{ 'MTR_PLAYLIST_FILTERS_APPLY' | translate }}
    </button>
  </div>
</div>
<ng-container *ngIf="dialogFilterClosed$ | async"></ng-container>
<ng-container *ngIf="previousFilterValues$ | async"></ng-container>
<ng-container *ngIf="hasFiltersActive$ | async"></ng-container>
