import { NgModule } from '@angular/core'
import { GtagClickDirective } from '@shared/modules/analytics-companion/directives/gtag-click/gtag-click.directive'
import { GtagDownloadDirective } from '@shared/modules/analytics-companion/directives/gtag-download/gtag-download.directive'
import { GtagItemSelectorDirective } from '@shared/modules/analytics-companion/directives/gtag-item-selector/gtag-item-selector.directive'
import { AnalyticsAttributesDirective } from './directives/analytics-attributes/analytics-attributes.directive'

const DIRECTIVES = [
  GtagClickDirective,
  GtagDownloadDirective,
  GtagItemSelectorDirective,
  AnalyticsAttributesDirective,
]

@NgModule({
  declarations: [...DIRECTIVES],
  exports: [...DIRECTIVES],
})
export class AnalyticsCompanionModule {}
