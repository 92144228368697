import { WidgetIdentifier } from '../enums/widget-identifier.enum'
import { WidgetPermission } from '@widgets/enums/widget-permission.enum'
import { WidgetSpaceType } from '@widgets/models/widget.models'

export const WIDGET_PERMISSION_MAP: Record<WidgetPermission, WidgetIdentifier[]> = {
  // team
  [WidgetPermission.teamGeneralWidgets]: [
    WidgetIdentifier.teamMatches,
    WidgetIdentifier.teamTopPlayers,
    WidgetIdentifier.teamRankedMetric,
    WidgetIdentifier.playerRankedMetric,
    WidgetIdentifier.goalkeeperRankedMetric,
    WidgetIdentifier.teamStats,
    WidgetIdentifier.top5Metrics,
    WidgetIdentifier.bottom5Metrics,
    WidgetIdentifier.sortableMetrics,
    WidgetIdentifier.freeMetrics,
    WidgetIdentifier.goalkeepers,
    WidgetIdentifier.squad,
    WidgetIdentifier.competitions,
    WidgetIdentifier.trend,
    WidgetIdentifier.manager,
    WidgetIdentifier.leaderboard,
  ],
  // player
  [WidgetPermission.playerGeneralWidgets]: [
    WidgetIdentifier.playerRankedMetric,
    WidgetIdentifier.playerStats,
    WidgetIdentifier.top5Metrics,
    WidgetIdentifier.bottom5Metrics,
    WidgetIdentifier.sortableMetrics,
    WidgetIdentifier.freeMetrics,
    WidgetIdentifier.competitions,
    WidgetIdentifier.goalkeeperRankedMetric,
    WidgetIdentifier.goalkeeperStats,
    WidgetIdentifier.goalkeeperCompetitions,
  ],
  // player career
  [WidgetPermission.playerCareerWidgets]: [
    WidgetIdentifier.honors,
    WidgetIdentifier.trajectory,
    WidgetIdentifier.transfers,
  ],
  // competitions
  [WidgetPermission.competitionGeneralWidgets]: [
    WidgetIdentifier.reports,
    WidgetIdentifier.competitionMatches,
    WidgetIdentifier.leaderboard,
    WidgetIdentifier.competitionTopPlayers,
    WidgetIdentifier.teamRankedMetric,
    WidgetIdentifier.playerRankedMetric,
    WidgetIdentifier.goalkeeperRankedMetric,
  ],
}

export const WIDGET_SPACE_TYPE_PERMISSION_MAP: Record<WidgetSpaceType, WidgetPermission[]> = {
  player: [WidgetPermission.playerCareerWidgets, WidgetPermission.playerGeneralWidgets],
  goalkeeper: [WidgetPermission.playerCareerWidgets, WidgetPermission.playerGeneralWidgets],
  competition: [WidgetPermission.competitionGeneralWidgets],
  team: [WidgetPermission.teamGeneralWidgets],
}
