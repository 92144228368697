import { Injectable, Type } from '@angular/core'
import { DialogConfig, DialogRef, DialogService } from '@mediacoach/ui'

@Injectable()
export class PlaylistService {
  filtersDialogRef: DialogRef
  constructor(private service: DialogService) {}

  openFiltersDialog(componentType: Type<any>, config: DialogConfig) {
    this.filtersDialogRef = this.service.open(componentType, config)
    this.filtersDialogRef.onClose.subscribe(() => {
      this.filtersDialogRef = null
    })
  }

  closeFiltersDialog() {
    if (this.filtersDialogRef) {
      this.filtersDialogRef.close()
    }
  }
}
