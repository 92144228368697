<div *ngIf="options?.length" class="c-segmented-control" [ngClass]="modifiers">
  <ion-segment
    (ionChange)="onSegmentValueChanged($event)"
    mode="ios"
    [value]="value"
    [disabled]="disabled"
  >
    <ion-segment-button
      *ngFor="let option of options"
      [value]="option.id"
      [disabled]="option.disabled"
      [ngClass]="option.extraClasses"
      [attributes]="option.attributes"
      [attr.data-qa-id]="option.qaId"
    >
      <span class="c-segmented-control__content">
        <ion-img *ngIf="option.image" [src]="option.image"></ion-img>
        <i *ngIf="option.icon" class="u-icon u-icon--{{ option.icon }}"></i>
        <ion-label *ngIf="option.label">{{ option.label | translate }}</ion-label>
      </span>
    </ion-segment-button>
  </ion-segment>
</div>
