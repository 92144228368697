export enum AssetGroupType {
  DesktopInstaller = 'DesktopInstaller',
  DesktopManual = 'DesktopManual',
  DesktopUtility = 'DesktopUtility',
  PortalManual = 'PortalManual',
  MobileInstaller = 'MobileInstaller',
  MobileManual = 'MobileManual',
  LiveProInstaller = 'LiveProInstaller',
  LiveProManual = 'LiveProManual',
  VenueDocs = 'VenueDocs',
}

export enum AssetOS {
  Windows = 'windows',
  IOS = 'ios',
  Android = 'android',
  MacOS = 'macos',
}

export enum AssetFile {
  MCDesktopWinInstaller = 'MediacoachDesktopInstaller.exe',
  MCDesktopMacInstaller = 'MediacoachDesktopInstaller.dmg',
  MCDesktopWinInstallManualEn = 'InstallManual_Win_en.pdf',
  MCDesktopWinInstallManualEs = 'InstallManual_Win_es.pdf',
  MCDesktopMacInstallManualEn = 'InstallManual_MacOS_en.pdf',
  MCDesktopMacInstallManualEs = 'InstallManual_MacOS_es.pdf',
  TeamViewer = 'TeamViewer',
  HASPUserSetup = 'HASPUserSetup.exe',
  SentinelRuntime = 'Sentinel_Runtime.dmg',
  MCPortalReportManual = 'Manual_Informes_MediacoachPortal.pdf',
  PCReportManual = 'Manual_Informes_Fisico_Complementario.pdf',
  WimuUserManual = 'Manual_Usuario_Wimu.pdf',
  MobileIOS = 'mobile-manifest.plist',
  MobileAndroid = 'MCMobile.apk',
  MobileIOSManual = 'Manual_Instalacion_MOBILE_iOS.pdf',
  MobileAndroidManual = 'Manual_Instalacion_MOBILE_Android.pdf',
  MobileUserManual = 'Manual_Usuario_Mobile.pdf',
  MCLiveProIOS = 'livepro-manifest.plist',
  McLiveProIOSManual = 'Manual_Instalacion_LIVEPRO_iOS.pdf',
  MCPowerBiWCSManual = 'Manual_Power_Bi_WCS.pdf',
  MCWCSManual = 'Manual_WCS.pdf',
  MCBeyondStatsManual = 'Manual_BeyondStats.pdf',
  MCAnalysisMediacoahManual = 'Manual_AnalisisMediacoach.pdf',
  MCPlayerDemarcations = 'Manual_Demarcaciones_Jugador.pdf',
}

export enum AssetExtension {
  EXE = 'EXE',
  PDF = 'PDF',
  APK = 'APK',
  IOS = 'IOS',
  DMG = 'DMG',
}

export enum AssetDisplayTabType {
  Competition = 'competition',
  BeyondStats = 'beyondStats',
}
