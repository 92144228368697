import { WidgetQuery } from '../../../shared/modules/widgets/models/widget.models'

export const SEASON_STR_SHORT_REGEXP = /\d{4}\/\d{2}/g

export const SPACE_RANKING_QUERY: WidgetQuery = {
  metricType: 'Average',
  top: 25,
  rankingType: 'Team',
  rankingOver: 'Competition',
  includeMember: true,
}
