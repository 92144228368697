import { environment } from '@env'
import { TitleSize } from '@shared/components/title/title.constants'
import { Directive, Input } from '@angular/core'
import { BoxModifier, ButtonType, Competition } from '@mediacoach-ui-library/global'

@Directive()
export abstract class DetailsWidgetBase {
  @Input() isNotDesktop: boolean
  @Input() competition: Competition
  @Input() loading: boolean

  readonly envType = environment.envType
  readonly titleSize = TitleSize.S
  readonly buttonIcon = ButtonType.Icon
  readonly boxModifiers: any[] = [BoxModifier.Bottom, BoxModifier.Horizontal, BoxModifier.Gradient]

  teamLoading: boolean
}
