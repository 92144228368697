import { createAction, props } from '@ngrx/store'
import { SpaceMetricDefinition } from '@core/space/models/space-metrics.models'
import { ApiTeam } from '@core/models/models/team.models'
import { ApiPlayer } from '@core/models/models/player.models'
import { SpaceDemarcationCategory, SpaceTeam } from '@core/space/models/space.models'
import { Widget } from '@widgets/models/widget.models'

export const setSpaceHeaderLoading = createAction(
  '[Space] set header loader',
  props<{ headerLoading: boolean }>(),
)

export const spaceNavigate = createAction('[Space] space navigate', (payload: any) => ({ payload }))

export const purgeSpace = createAction('[Space] purge space')

export const fetchSpaceTeamMetricDefinition = createAction('[Space] fetch team metric definition')

export const setSpaceTeamMetricDefinition = createAction(
  '[Space] set team metric definition',
  props<{ teamMetricDefinition: SpaceMetricDefinition[] }>(),
)

export const fetchSpacePlayerMetricDefinition = createAction(
  '[Space] fetch player metric definition',
)

export const setSpacePlayerMetricDefinition = createAction(
  '[Space] set player metric definition',
  props<{ playerMetricDefinition: SpaceMetricDefinition[] }>(),
)

export const fetchSpaceGoalkeeperMetricDefinition = createAction(
  '[Space] fetch goalkeeper metric definition',
)

export const setSpaceGoalkeeperMetricDefinition = createAction(
  '[Space] set goalkeeper metric definition',
  props<{ goalkeeperMetricDefinition: SpaceMetricDefinition[] }>(),
)
export const fetchSpaceDemarcations = createAction('[Space] fetch demartacions')

export const setSpaceDemarcations = createAction(
  '[Space] set demartacions',
  props<{ demarcations: SpaceDemarcationCategory[] }>(),
)

export const setSpaceDialogSearchText = createAction(
  '[Space] set dialog search text',
  props<{ text: string }>(),
)

export const setSpaceDialogSearchLoading = createAction(
  '[Space] set dialog search loading',
  props<{ loading: boolean }>(),
)

export const navigateToTeamSpace = createAction(
  '[Space] navigate to teamspace',
  props<{ team: SpaceTeam }>(),
)

export const setSpaceDialogSearchScope = createAction(
  '[Space] set dialog search scope',
  props<{ scope: 'team' | 'player' | string }>(),
)

export const setSpaceDialogSearchResults = createAction(
  '[Space] set dialog search results',
  props<{ results: (ApiTeam | ApiPlayer)[] }>(),
)

export const fetchSpaceDialogTeams = createAction(
  '[Space] fetch dialog teams',
  props<{ seasonId: string; competitionId: string }>(),
)

export const searchSpaceDialogPlayers = createAction(
  '[Space] search dialog players',
  props<{ seasonId: string; competitionId: string }>(),
)

export const openSpaceDialogMetricRanking = createAction(
  '[Space] open dialog metric ranking',
  props<{
    metricKey: string
    widget: Widget
    isPlayerRanking?: boolean
  }>(),
)
