import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  QueryList,
  ViewChildren,
} from '@angular/core'
import { debounce, DialogConfig } from '@mediacoach/ui'
import {
  setSpaceDialogSearchResults,
  setSpaceDialogSearchScope,
  setSpaceDialogSearchText,
} from '@core/space/state/actions/space.actions'
import {
  selectSpaceDialogSearchLoading,
  selectSpaceDialogSearchText,
} from '@core/space/state/selectors/space.selectors'
import { Subject } from 'rxjs'
import { debounceTime, startWith, tap } from 'rxjs/operators'
import {
  WidgetDialogConfig,
  WidgetDisplayMode,
  WidgetQuery,
  WidgetQueryCompare,
} from '@widgets/models/widget.models'
import { WidgetIdentifier } from '@widgets/enums/widget-identifier.enum'
import { SPACE_MIN_CHAR_TO_FILTER } from '@core/space/utils/space-dialog.utils'
import { WIDGET_AGGREGATION_I18N_MAP } from '@widgets/constants/widget.constants'
import { scrollByElementList } from '@core/utils/dom.utils'
import { DialogSeasonCompetitionSelectorBase } from '../../base-classes/dialog-season-competition-selector.base'

@Component({
  selector: 'mcp-dialog-vs-selector',
  templateUrl: './dialog-vs-selector.component.html',
  styleUrl: './dialog-vs-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogVsSelectorComponent
  extends DialogSeasonCompetitionSelectorBase
  implements OnDestroy
{
  @ViewChildren('selectorElement') selectorElements: QueryList<ElementRef>
  readonly results$ = this._store.select(this.dialogConfig.resultsSelector).pipe(
    tap((results) => {
      if (
        results?.length &&
        this.againstToItem &&
        !results?.find(({ id }) => id === this.againstToItem.id)
      ) {
        this.againstToItem = null
      }
      if (!this.vsCompetition && this.compare && results?.length) {
        this.againstToItem = results.find(({ id }) => id === this.toCompareId)
      }
      this._autoScrollToActiveItems()
    }),
  )
  readonly textSearched$ = this._store.select(selectSpaceDialogSearchText)
  readonly loading$ = this._store.select(selectSpaceDialogSearchLoading)
  readonly textSubject$: Subject<string> = new Subject<string>()
  readonly text$ = this.textSubject$.pipe(
    debounceTime(500),
    startWith(
      this.dialogConfig?.againstTo === 'player' && this.againstToMetadata?.label
        ? this.againstToMetadata?.label
        : '',
    ),
    tap((text) => this._store.dispatch(setSpaceDialogSearchText({ text: (text || '').trim() }))),
  )
  readonly aggregationTrans = WIDGET_AGGREGATION_I18N_MAP
  readonly spaceMinCharToFilter = SPACE_MIN_CHAR_TO_FILTER
  readonly spiderChartWidgetIds = [
    WidgetIdentifier.teamStats,
    WidgetIdentifier.playerStats,
    WidgetIdentifier.goalkeeperStats,
  ]

  againstToItem: any
  vsCompetition: boolean
  isVsCompetition: boolean

  override get seasonId(): string {
    return this.compare?.seasonId || this.data?.seasonId
  }

  override get competitionId(): string {
    return this.compare?.competitionId || this.data?.competitionId
  }

  get dialogConfig(): WidgetDialogConfig {
    return this.data?.config
  }

  private get isSpiderChartWidget() {
    return this.spiderChartWidgetIds.includes(this.widget.id as WidgetIdentifier)
  }

  get toCompareId() {
    return (this.compare || {})[this.dialogConfig.againstToProperty]
  }

  get queryCompareByAgainstTo() {
    return this.dialogConfig.againstTo === 'player'
      ? {
          teamId: this.againstToItem.teamId || this.compare?.teamId,
          [this.dialogConfig.againstToProperty]: this.againstToItem?.id,
        }
      : { [this.dialogConfig.againstToProperty]: this.againstToItem?.id }
  }

  private get _includeSeasonCompetition() {
    return this.isSpiderChartWidget ? {} : { includeSeasonCompetition: !!this.vsCompetition }
  }

  constructor(_dialogConfig: DialogConfig) {
    super(_dialogConfig)
    this._store.dispatch(setSpaceDialogSearchScope({ scope: this.dialogConfig.againstTo }))
    this.isVsCompetition = this.againstToMetadata?.vsCompetition || !this.query?.compare
    this.vsCompetition = this.againstToMetadata?.vsCompetition || !this.query?.compare
    this.searchData()
  }

  ngOnDestroy() {
    this._store.dispatch(setSpaceDialogSearchText({ text: null }))
    this._store.dispatch(setSpaceDialogSearchResults({ results: null }))
  }

  applySelection() {
    const compare: WidgetQueryCompare = {
      seasonId: this.selectedSeason.id,
      competitionId: this.selectedCompetition.id,
      ...(!this.vsCompetition ? this.queryCompareByAgainstTo : {}),
    }
    const query: WidgetQuery = {
      ...this.query,
      ...this._includeSeasonCompetition,
      ...(this.isSpiderChartWidget ? { compare } : { compare: [compare] }),
    }
    const displayMode: WidgetDisplayMode = {
      ...this.widget.displayMode,
      againstToMetadata: !this.vsCompetition
        ? {
            label: this.againstToItem?.formattedName || this.againstToItem?.name,
            image: this.againstToItem?.portraitLogo,
          }
        : { vsCompetition: true },
      againstTo: !this.vsCompetition ? this.dialogConfig.againstTo : 'competition',
    }
    this._dialogRef.close({ ...this.widget, query, displayMode })
  }

  searchData() {
    this._store.dispatch(
      this.dialogConfig.searchAction({
        seasonId: this.selectedSeason.id,
        competitionId: this.selectedCompetition.id,
      }),
    )
  }

  onSearch(text: string) {
    this.textSubject$.next(text)
  }

  @debounce(0)
  private _autoScrollToActiveItems() {
    scrollByElementList(this.selectorElements, 'mcp-widget-dialog__item--active')
  }
}
