import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { Widget, WidgetColumn, WidgetRow } from '@widgets/models/widget.models'

@Component({
  selector: 'mcp-widget-table-cell',
  templateUrl: './widget-table-cell.component.html',
  styleUrl: './widget-table-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetTableCellComponent<T> {
  @Input() widget: Widget
  @Input() column: WidgetColumn
  @Input() row: WidgetRow<T>
  @Input() isFirstColumn: boolean
}
