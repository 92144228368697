import { ActionReducer, ActionReducerMap, MetaReducer, StoreModule, UPDATE } from '@ngrx/store'
import { extModules } from '../../build-config/ngrx/ngrx-config'
import { InjectionToken, NgModule } from '@angular/core'
import { EffectsModule } from '@ngrx/effects'
import { StoreToken } from '@core/state/enums/store-token.enum'
import { userReducer } from '@core/state/reducers/user.reducer'
import { OidcEffects } from '@core/state/effects/oidc.effects'
import { socketReducer } from '@core/state/reducers/socket.reducer'
import { SocketEffects } from '@core/state/effects/socket.effects'
import { ProfileEffects } from '@core/state/effects/profile.effects'
import { seasonsReducer } from '@core/state/reducers/seasons.reducer'
import { SeasonsEffects } from '@core/state/effects/seasons.effects'
import { StartupEffects } from '@core/state/effects/startup.effects'
import { assetsReducer } from '@core/state/reducers/assets.reducer'
import { AssetsEffects } from '@core/state/effects/assets.effects'
import { StorageEffects } from '@core/state/effects/storage.effects'
import { ErrorEffects } from '@core/state/effects/error.effects'
import { navigationReducer } from '@core/state/reducers/navigation.reducer'
import { NavigationEffects } from '@core/state/effects/navigation.effects'
import { streamReducer } from './reducers/stream.reducer'
import { StreamEffects } from '@core/state/effects/stream.effects'
import {
  MCP_FEATURE_SELECTOR,
  MCP_STORE_IDENTIFIER,
} from '@core/injection-tokens/merged-store.token'
import { streamFeatureSelector } from '@core/state/selectors/stream.selectors'
import { selectionReducer } from '@core/state/reducers/selection.reducer'
import { SelectionEffects } from '@core/state/effects/selection.effects'
import { PowerBiEffects } from '@core/state/effects/power-bi.effects'
import { RECOMPUTE } from '@ngrx/store-devtools'

export const getReducers = (): ActionReducerMap<any> => ({
  [StoreToken.user]: userReducer,
  [StoreToken.socket]: socketReducer,
  [StoreToken.seasons]: seasonsReducer,
  [StoreToken.assets]: assetsReducer,
  [StoreToken.navigation]: navigationReducer,
  [StoreToken.stream]: streamReducer,
  [StoreToken.selection]: selectionReducer,
})
export const debug = (reducer: ActionReducer<any>): ActionReducer<any> =>
  function (state, action) {
    if (![UPDATE, RECOMPUTE as string].includes(action.type)) {
      //console.log('action', action)
    }
    return reducer(state, action)
  }
export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>('global')
export const metaReducers: MetaReducer<any>[] = [debug]

const EFFECTS = [
  OidcEffects,
  StartupEffects,
  SocketEffects,
  ProfileEffects,
  SeasonsEffects,
  AssetsEffects,
  StorageEffects,
  ErrorEffects,
  NavigationEffects,
  StreamEffects,
  SelectionEffects,
  PowerBiEffects,
]

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot(REDUCER_TOKEN, {
      metaReducers,
    }),
    extModules,
    EffectsModule.forRoot([...EFFECTS]),
  ],
  providers: [
    { provide: REDUCER_TOKEN, useFactory: getReducers },
    { provide: MCP_STORE_IDENTIFIER, useValue: StoreToken.stream },
    { provide: MCP_FEATURE_SELECTOR, useValue: streamFeatureSelector },
  ],
})
export class StateModule {}
