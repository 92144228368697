import { Component, EventEmitter, Input, Output } from '@angular/core'
import { SegmentModifier } from '@shared/components/segmented-control/segmented-control.models'
import { GetTeamProp, PlayerListItem, TeamType } from '@mediacoach-ui-library/global'
import { environment } from '@env'
import { parseMatch } from '@shared/components/player-comparison/player-comparison.utils'
import { DialogBase } from '@shared/components/dialog/dialog.base'
import { ComparablePlayer } from '@core/models/dto/player.dto'

@Component({
  selector: 'app-player-comparison',
  templateUrl: './player-comparison.component.html',
  styleUrls: ['./player-comparison.component.theme.scss', './player-comparison.component.scss'],
})
export class PlayerComparisonComponent extends DialogBase {
  private _match

  selectedTeam = TeamType.Home
  SegmentModifier = SegmentModifier
  envType = environment.envType

  @Output() playerSelect: EventEmitter<{ playerA: ComparablePlayer; playerB: ComparablePlayer }> =
    new EventEmitter()

  @Input() teamType: TeamType = TeamType.Home
  @Input() teamSegmentOptions = []

  @Input()
  set match(data) {
    this._match = data
    if (data) {
      this._match = parseMatch(data)
    }
  }

  get match() {
    return this._match
  }

  initialPlayer

  private _findPlayer(
    { id }: { id: string },
    teamType?: TeamType,
  ): Partial<PlayerListItem> & { nameAndDorsal: string } {
    const teamKey = GetTeamProp(teamType || this.teamType)
    try {
      const player = (
        this.match[teamKey].squad &&
        this.match[teamKey].squad.players &&
        this.match[teamKey].squad.players.length > 0
          ? this.match[teamKey].squad
          : this.match[teamKey].lineup
      ).players.find((p) => p.id === id)
      return {
        ...player,
        nameAndDorsal: `${player.shirtNumber}. ${player.formattedName}`,
      }
    } catch (err) {
      console.error('there\'s no match!')
    }
  }

  openDialog(player: any, scrollableRef: string = '.match-details__metrics') {
    this.initialPlayer = this._findPlayer(player) || null
    super.open()

    setTimeout(() => {
      document.querySelector(scrollableRef).scrollIntoView()
    }, 100)
  }

  onTeamChange(team): void {
    this.selectedTeam = team
  }
}
