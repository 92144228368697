import {
  SpaceChartMetric,
  SpaceFormattedMetric,
  SpaceMetric,
  SpaceMetricDefinition,
  SpaceMetricResult,
  SpaceMetricValue,
  SpaceRankedMetric,
  SpaceRankedMetricPayload,
} from '../models/space-metrics.models'
import {
  Widget,
  WidgetDisplayMode,
  WidgetQuery,
  WidgetQueryCompare,
} from '@widgets/models/widget.models'
import { deepClone, exists, SpiderChartData } from '@mediacoach/ui'
import { distinctItems } from '@core/utils/collection.utils'
import { ApiChartMetricResult } from '@core/models/models/metric.models'
import { buildTeamSpaceNavUrl } from '@features/team-space/utils/team-space.utils'
import { buildPlayerSpaceNavUrl } from '@features/player-space/utils/player-space.utils'

export const extractSelectedMetrics = (
  metrics: SpaceMetricDefinition[],
  sortReference?: string[],
): string[] => {
  let selectedMetrics: string[] = []
  metrics.forEach((metric) => {
    if (metric.selected && metric.selected.length > 0) {
      selectedMetrics = [...selectedMetrics, ...metric.selected]
    }
    if (metric.groups) {
      selectedMetrics = [...selectedMetrics, ...extractSelectedMetrics(metric.groups)]
    }
  })

  if (sortReference?.length) {
    selectedMetrics = sortReference.reduce((list, s) => {
      const item = selectedMetrics.find((m) => s === m)
      if (item) {
        list.push(item)
      }
      return list
    }, [])
  }

  return selectedMetrics
}

export const getRankedRouteFnByDimension = (metric: SpaceRankedMetric) => ({
  Team: buildTeamSpaceNavUrl(metric.seasonId, metric.competitionId, metric.refererId),
  Player: buildPlayerSpaceNavUrl(
    metric.refererId,
    metric.teamId,
    metric.seasonId,
    metric.competitionId,
  ),
})

const shouldHighlight = (
  displayMode: WidgetDisplayMode,
  itemId: string,
  ranking: SpaceRankedMetric,
): boolean =>
  (ranking.refererId === itemId && displayMode.rowHighlight) ||
  (ranking.teamId === itemId &&
    displayMode.rowHighlight &&
    displayMode.itemBeforeTabs === 'selectableAll')

export const buildStickyStyles = (
  displayMode: WidgetDisplayMode,
  top: string,
): Partial<CSSStyleDeclaration> => {
  const styles: Partial<CSSStyleDeclaration> = {}

  if (!displayMode.preventStickyOnHighlight) {
    styles.top = top
    styles.bottom = '0'
    styles.position = 'sticky'
  }

  return styles
}

const getComparedItems = (data: SpaceRankedMetricPayload): string[] => {
  const { itemId, compareId, againstCompetition } = data || ({} as any)
  return ((compareId || againstCompetition ? data.rankings : []) || [])
    .filter(({ refererId }) => refererId === itemId || refererId === compareId)
    .map(({ refererId }) => refererId)
}

const buildComparedItemStyleClass = (
  comparedItems: string[],
  metric: SpaceRankedMetric,
  data: SpaceRankedMetricPayload,
): string => {
  return [
    comparedItems.includes(metric.refererId) ? 'compare-row-highlight ' : null,
    metric.refererId === data.itemId ? 'is-left' : null,
    metric.refererId === comparedItems[0] && comparedItems.length > 1 ? 'first-selected' : null,
    metric.refererId === comparedItems[1] ? 'last-selected' : null,
  ]
    .filter(Boolean)
    .join(' ')
}

const isMetricHighlighted = (metric: SpaceRankedMetric, data: SpaceRankedMetricPayload): boolean =>
  !data.againstCompetition && shouldHighlight(data.displayMode, data.itemId, metric)

const formatMetricValue = (metric: SpaceRankedMetric, displayMode: WidgetDisplayMode): string =>
  displayMode?.aggregation === 'averageValue'
    ? metric?.valueRaw.toFixed(2)
    : metric?.value.toString()

const formatMetricPlayer = (metric: SpaceRankedMetric): any => {
  return metric.rank === -1
    ? {
        playerImage: metric.competitionImage || metric.teamImage,
        showHyphen: true,
        teamImage: metric.competitionImage || metric.teamImage,
      }
    : { playerImage: metric.playerImage, showHyphen: false, teamImage: metric.teamImage }
}

const buildMetricStyles = (
  metric: SpaceRankedMetric,
  data: SpaceRankedMetricPayload,
): { styles: Partial<CSSStyleDeclaration>; styleClass: string } => {
  const comparedItems = getComparedItems(data)
  const isHighlighted = isMetricHighlighted(metric, data)
  const compareHighlightedClass = comparedItems?.length
    ? buildComparedItemStyleClass(comparedItems, metric, data)
    : ''

  return {
    styles: isHighlighted ? buildStickyStyles(data.displayMode, data.top || '45px') : {},
    styleClass: isHighlighted ? 'row-highlight' : compareHighlightedClass,
  }
}
export const formatRankedMetrics = (data: SpaceRankedMetricPayload): SpaceRankedMetric[] => {
  return (data.rankings || []).map((r) => {
    return {
      ...r,
      position: r.rank !== -1 ? r.rank : '#',
      ranked: `${r?.rank}/${r?.outOf}`,
      valueFormatted: formatMetricValue(r, data.displayMode),
      playerFormatted: `${exists(r.shirtNumber) ? r.shirtNumber + '.' : ''} ${r.name}`,
      ...formatMetricPlayer(r),
      ...buildMetricStyles(r, data),
      route: getRankedRouteFnByDimension(r)[r.dimension],
      teamRoute:
        r.dimension === 'Player'
          ? buildTeamSpaceNavUrl(r.seasonId, r.competitionId, r.teamId)
          : null,
    }
  })
}

export const sortRankedMetrics = (
  metrics: SpaceRankedMetric[],
  sortedList: string[],
): SpaceRankedMetric[] => {
  return sortedList.length
    ? sortedList.reduce((list, metric) => {
        const item = metrics.find((m) => m.metricKey === metric)
        if (item) {
          list.push(item)
        }
        return list
      }, [])
    : metrics
}

const findDefinition = (definition: SpaceMetricDefinition[], metric) => {
  for (const def of definition) {
    if (def.metrics.find((d) => d === metric.metricName)) {
      return { def }
    }
    for (const group of def.groups) {
      if (group.metrics.find((d) => d === metric.metricName)) {
        return { def, group }
      }
    }
  }
}

const definitionAsAWidgetMetrics = (definition: SpaceMetricDefinition[]) =>
  definition?.reduce(
    (rData, definitionItem) => [
      ...rData,
      ...definitionItem.metrics.map((metricName) => ({ metricName })),
      ...definitionItem.groups.reduce(
        (rGroupData, definitionGroupItem) => [
          ...rGroupData,
          ...definitionGroupItem.metrics.map((metricName) => ({ metricName })),
        ],
        [],
      ),
    ],
    [] as SpaceFormattedMetric[],
  )

const buildNewDefinition = (
  def: SpaceMetricDefinition,
  group: SpaceMetricDefinition,
  metric: any,
): any => {
  return {
    label: def.name,
    sections: [
      {
        label: group?.name,
        metrics: [metric],
      },
    ],
  }
}

const handleExistenDefinition = (
  definitions: any[],
  idx: number,
  group: SpaceMetricDefinition,
  metric: any,
): void => {
  const gIdx = definitions.length
    ? (definitions[idx]?.sections || []).findIndex((g) => g.label === group?.name)
    : null
  if (!group) {
    definitions[idx].sections[0].metrics.push(metric)
  } else if (!!group && gIdx !== -1) {
    definitions[idx].sections[gIdx].metrics.push(metric)
  } else {
    definitions[idx].sections = [
      ...(definitions[idx].sections?.length ? [...definitions[idx].sections] : []),
      {
        label: group.name,
        metrics: [metric],
      },
    ]
  }
}

export const mapMetricDefinitionWithSortedMetrics = (
  definition: SpaceMetricDefinition[],
  widgetMetrics: SpaceFormattedMetric[],
) => {
  const definitions = []
  const widgetMetricsData = widgetMetrics?.length
    ? widgetMetrics
    : definitionAsAWidgetMetrics(definition)
  widgetMetricsData?.forEach((metric) => {
    const _definition = findDefinition(definition, metric)
    if (_definition) {
      const { def, group } = _definition
      const idx = definitions.findIndex((d) => d.label === def.name)
      if (idx === -1) {
        definitions.push(buildNewDefinition(def, group, metric))
      } else {
        handleExistenDefinition(definitions, idx, group, metric)
      }
    }
  })
  return definitions
}

export const initializeSelectedList = (metrics: SpaceMetricDefinition[]) => {
  metrics.forEach((metric) => {
    metric.selected = metric.selected || []
    if (metric.groups) {
      initializeSelectedList(metric.groups)
    }
  })
}

export const selectMetricsOverDefinitions = (list: SpaceMetricDefinition[], metric: string) => {
  for (const item of list) {
    if (item.metrics.includes(metric) && !item.selected?.includes(metric)) {
      item.selected.push(metric)
      break
    }

    if (item.groups?.length) {
      selectMetricsOverDefinitions(item.groups, metric)
    }
  }
}

export const selectAllDefinitions = (list: SpaceMetricDefinition[]) => {
  for (const item of list) {
    item.selected = [...item.metrics]

    if (item.groups?.length) {
      selectAllDefinitions(item.groups)
    }
  }
}

const getDefs = (defs: any) => (!Array.isArray(defs) && !Object.keys(defs).length ? [] : defs)

export const handleMetricDefinitionPreselectedByResult = (
  definitions: SpaceMetricDefinition[],
  widgetData: any,
  widget: Widget,
): SpaceMetricDefinition[] => {
  const defs = getDefs(deepClone(definitions))
  const metricNames: string[] = [
    ...new Set(widgetData?.map((w: any) => w.metricKey || w.metricName || w.key) || []),
  ] as string[]

  if (!widget?.query?.metricNames?.length && widget?.displayMode?.selectAllMetricsOnEmpty) {
    selectAllDefinitions(defs)
  } else {
    ;(metricNames || []).forEach((metric) => {
      selectMetricsOverDefinitions(defs, metric)
    })
  }

  return defs
}

export const getSelectedMetricDefinitionCategoryIndex = (
  definitions: SpaceMetricDefinition[],
): number => {
  for (let i = 0; i < definitions.length; i++) {
    if (definitions[i]?.selected?.length) {
      return i
    }
    if (definitions[i]?.groups?.length) {
      const groupIdx = getSelectedMetricDefinitionCategoryIndex(definitions[i].groups)
      if (groupIdx !== -1) {
        return i
      }
    }
  }
  return -1
}

export const formatSpaceSpiderChartMetrics = (
  metrics: SpaceChartMetric,
  metricNames: string[],
): {
  averageValue?: SpiderChartData[]
  accumulatedValue?: SpiderChartData[]
  charKeys?: string[]
} => {
  const defaultData = metricNames?.map((key) => ({ key })) as ApiChartMetricResult[]
  const data = metrics?.chartMetricResults?.length ? metrics.chartMetricResults : defaultData

  const averageValue: SpiderChartData[] = data.map((metricResult) => ({
    keys: [metricResult.key],
    values: [
      metricResult?.leftAverageMetricResult?.value,
      metricResult?.rightAverageMetricResult?.value,
    ],
    labelValues: [
      metricResult?.leftAverageMetricResult?.label as number,
      metricResult?.rightAverageMetricResult?.label as number,
    ],
    unit: metricResult?.leftAverageMetricResult?.unit,
  }))

  const keysToTranslate = data.reduce(
    (cValue, item) => [
      ...cValue,
      item.key,
      ...(item?.leftAverageMetricResult?.unit ? [item?.leftAverageMetricResult?.unit] : []),
    ],
    [],
  )

  return { averageValue, accumulatedValue: [], charKeys: distinctItems(keysToTranslate) }
}

const cleanUpMetricSpaceMetric = (metric: SpaceMetric): SpaceMetric => {
  const keysToKeep = ['metricName', 'key', 'unit']
  return {
    ...metric,
    metricResults: metric.metricResults.map((m) =>
      Object.keys(m).reduce(
        (obj, key) => ({
          ...obj,
          [key]: keysToKeep.includes(key) ? m[key] : null,
        }),
        {},
      ),
    ),
  }
}

const isComparedMetric = (metric: SpaceMetric, comparison: WidgetQueryCompare): boolean =>
  metric?.seasonId === comparison?.seasonId &&
  metric?.competitionId === comparison?.competitionId &&
  ((comparison?.playerId && comparison?.playerId === metric?.id) ||
    (comparison?.teamId && comparison?.teamId === metric?.id) ||
    (!comparison?.teamId && !comparison?.playerId))

const isComparedEntity = (
  metric: SpaceMetric,
  comparison: WidgetQueryCompare | WidgetQueryCompare[],
): boolean =>
  Array.isArray(comparison)
    ? !!comparison.find((cmp) => isComparedMetric(metric, cmp))
    : isComparedMetric(metric, comparison)

const resolveMissingEntities = (
  metrics: SpaceMetric[],
  query: WidgetQuery,
): { left: SpaceMetric; right: SpaceMetric } => {
  let left: SpaceMetric = null
  let right: SpaceMetric = null

  if (!metrics) {
    return { left, right }
  }

  for (const metric of metrics) {
    if (left && right) {
      return { left, right }
    } else if (
      metric.seasonId === query.seasonId &&
      metric.competitionId === query.competitionId &&
      (metric.id === query.playerId || metric.id === query.teamId)
    ) {
      left = metric
    } else if (isComparedEntity(metric, query.compare)) {
      right = metric
    }
  }

  if (!left) {
    left = cleanUpMetricSpaceMetric(right)
  }

  return { left, right }
}

const resolveEntities = (
  metrics: SpaceMetric[],
  targetQuery: WidgetQuery,
): { left: SpaceMetric; right: SpaceMetric } => {
  if (metrics?.length === 2) {
    return {
      left: metrics[0],
      right: metrics[1],
    }
  }

  const { left, right } = resolveMissingEntities(metrics, targetQuery)
  return { left, right }
}

const mapLeftAggregation = (aggregations: string[], item: SpaceMetricResult): SpaceMetricValue =>
  aggregations.reduce(
    (rData, aggregation) => ({
      ...rData,
      [`${aggregation}Value`]: item[`${aggregation}Value`],
    }),
    {} as SpaceMetricValue,
  )

const mapRightAggregation = (
  aggregations: string[],
  rightList: SpaceMetricResult[],
  i: number,
  targetPosition: string,
): SpaceMetricValue =>
  aggregations.reduce(
    (rData, aggregation) => ({
      ...rData,
      [`${aggregation}Value`]:
        (rightList[i] || {})[`${aggregation}Value`] ??
        (rightList[i] || {})[`${aggregation}${targetPosition}s`],
    }),
    {} as SpaceMetricValue,
  )

export const formatSpaceMetrics = (
  metrics: SpaceMetric[],
  targetQuery: WidgetQuery,
  targetPosition?: string,
): SpaceFormattedMetric[] => {
  const { left, right } = resolveEntities(metrics, targetQuery)

  const leftList = left?.metricResults ?? []
  const rightList = right?.metricResults ?? []

  const aggregations = ['average', 'accumulated']

  return leftList.reduce(
    (returnData: any, item: any, i: number) => [
      ...returnData,
      {
        metricName: item.metricName,
        unit: item.unit,
        left: mapLeftAggregation(aggregations, item),
        right: mapRightAggregation(aggregations, rightList, i, targetPosition),
      },
    ],
    [] as SpaceFormattedMetric[],
  )
}

export const resolveAggregation = (widget: Widget): string => {
  const aggregation = widget.displayMode?.aggregation || widget.query?.metricType

  switch (aggregation) {
    case 'Accumulate':
    case 'accumulatedValue':
      return 'MTR_COMMON_ACCUMULATED'

    case 'Average':
    case 'averageValue':
      return 'MTR_COMMON_AVERAGE'
  }
}
