import { createSelector, DefaultProjectorFn, MemoizedSelector, select } from '@ngrx/store'
import {
  getMatch,
  isPlayListTabSelected,
} from '@core/state/selectors/stream-match.merged-selectors'
import { hasFiltersActive } from '@features/playlist/utils/playlist.utils'
import { SafeObjectData } from '@mediacoach-ui-library/global'
import { PlayListConfig } from '@features/playlist/models/playlist.models'
import { StreamState } from '@core/state/models/stream.state'
import { FilterType } from '@features/playlist/enums/playlist.enums'
import { pipe } from 'rxjs'
import { filter, map } from 'rxjs/operators'

export const selectPlaylist = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(feature, (state) => state?.playlist)

export const selectTags = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectPlaylist(feature), (state) => state?.tags)

export const selectPlaylists = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectPlaylist(feature), (state) => state?.playlists)

const selectRawSelectedPlaylist = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectPlaylist(feature), (state) => state?.selectedPlaylist)

export const selectCurrentSelectedPlaylist = (
  feature: MemoizedSelector<object, StreamState, DefaultProjectorFn<StreamState>>,
) =>
  createSelector(
    selectRawSelectedPlaylist(feature),
    getMatch(feature),
    (selectedPlaylist, currentMatch) =>
      selectedPlaylist && currentMatch && selectedPlaylist[currentMatch.id],
  )

export const selectSelectedTagItem = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectPlaylist(feature), (state) => state?.selectedTagItem)

export const selectPlaylistLoader = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectPlaylist(feature), (state) => state?.playlistLoader)

export const selectActiveFilters = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectPlaylist(feature), (state) => state?.filtersActive)

export const selectRawSortFilter = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectPlaylist(feature), (state) => state?.sortFilter)

const selectRawFilterValues = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectPlaylist(feature), (state) => state?.filterValues)

export const selectParsedTagItems = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectPlaylist(feature), (state) => state?.parsedTagItems)

export const selectIsDeletingTagItem = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectPlaylist(feature), (state) => state?.isDeletingTagItem)

export const selectFilterConfigByType = <T extends StreamState>(
  type: FilterType,
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) =>
  createSelector(
    selectPlaylist(feature),
    (state) => state?.filterConfig && state.filterConfig[type],
  )

export const selectFilterValuesByType = (
  type: FilterType,
  feature: MemoizedSelector<object, StreamState, DefaultProjectorFn<StreamState>>,
) =>
  createSelector(
    selectRawFilterValues(feature),
    getMatch(feature),
    (filterValues, currentMatch) =>
      filterValues && currentMatch && SafeObjectData(filterValues, [currentMatch.id, type], []),
  )

export const selectCurrentMatchFilterValues = (
  feature: MemoizedSelector<object, StreamState, DefaultProjectorFn<StreamState>>,
) =>
  createSelector(
    selectRawFilterValues(feature),
    getMatch(feature),
    (filterValues, currentMatch) => filterValues && currentMatch && filterValues[currentMatch.id],
  )

export const selectCurrentSortFilter = (
  feature: MemoizedSelector<object, StreamState, DefaultProjectorFn<StreamState>>,
) =>
  createSelector(
    selectRawSortFilter(feature),
    getMatch(feature),
    (sortFilter, currentMatch) => sortFilter && currentMatch && sortFilter[currentMatch.id],
  )

export const selectCurrentMatchFiltersActive = (
  feature: MemoizedSelector<object, StreamState, DefaultProjectorFn<StreamState>>,
) =>
  createSelector(
    selectActiveFilters(feature),
    getMatch(feature),
    (filtersActive, currentMatch) =>
      filtersActive && currentMatch && filtersActive[currentMatch.id],
  )

export const selectHasFiltersActive = (
  feature: MemoizedSelector<object, StreamState, DefaultProjectorFn<StreamState>>,
) =>
  createSelector(
    selectActiveFilters(feature),
    getMatch(feature),
    (filtersActive, currentMatch) =>
      filtersActive && currentMatch && hasFiltersActive(filtersActive[currentMatch.id]),
  )

export const selectParsedTagItemsWithConfig = (
  feature: MemoizedSelector<object, StreamState, DefaultProjectorFn<StreamState>>,
) =>
  pipe(
    select(
      createSelector(
        selectParsedTagItems(feature),
        isPlayListTabSelected(feature),
        (parsedTagItems, isPListTabSelected) => ({
          parsedTagItems,
          isPListTabSelected,
        }),
      ),
    ),
    filter(({ isPListTabSelected }) => !!isPListTabSelected),
    map(({ parsedTagItems }) => parsedTagItems),
  )

export const selectPlaylistConfig = (
  feature: MemoizedSelector<object, StreamState, DefaultProjectorFn<StreamState>>,
) =>
  createSelector(
    isPlayListTabSelected(feature),
    selectTags(feature),
    selectHasFiltersActive(feature),
    selectSelectedTagItem(feature),
    (playListTabSelected, tags, hasActiveFilters, selectedTagItem) =>
      ({
        hideVideoSource: playListTabSelected && !tags?.pager?.count,
        notHasTags: !hasActiveFilters && !tags?.pager?.count,
        playListTabSelected,
        selectedTagItem,
      }) as PlayListConfig,
  )

export const selectTagItemToPlay = (
  feature: MemoizedSelector<object, StreamState, DefaultProjectorFn<StreamState>>,
) =>
  pipe(
    select(
      createSelector(
        selectIsDeletingTagItem(feature),
        isPlayListTabSelected(feature),
        selectSelectedTagItem(feature),
        (isDeletingTagItem, isPlaylistSelected, selectedTagItem) => ({
          isDeletingTagItem,
          isPlaylistSelected,
          selectedTagItem,
        }),
      ),
    ),
    filter(({ isPlaylistSelected }) => !!isPlaylistSelected),
  )
