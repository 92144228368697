import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { McpTeam } from '@core/models/dto/team.dto'
import { McpPlayer } from '@core/models/dto/player.dto'
import { Loadable } from '@core/models/models/loadable.models'
import { Competition, PLACEHOLDER_IMAGES } from '@mediacoach-ui-library/global'

@Component({
  selector: 'mcp-widget-item-selector',
  templateUrl: './widget-item-selector.component.html',
  styleUrls: ['./widget-item-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetItemSelectorComponent implements OnInit {
  @Input() isPlayer = false

  @Input() set items(_t: Loadable<McpTeam[] | McpPlayer[]>) {
    this.loading = _t.loading
    if (_t?.value) {
      this.list = _t.value
    }
  }

  @Input() externalLoading: boolean
  @Input() selectedIndex = 0
  @Input() enableForceSelect: boolean
  @Input() disableSelect: boolean
  @Input() isNotAvailableContent: boolean
  @Input() competition: Competition
  @Output() selectItem = new EventEmitter<McpTeam | McpPlayer>()

  list: McpTeam[] | McpPlayer[]
  loading: boolean
  imagePlaceholder = ''

  ngOnInit(): void {
    this.imagePlaceholder = this.isPlayer ? PLACEHOLDER_IMAGES.PLAYER : PLACEHOLDER_IMAGES.TEAM
  }
}
