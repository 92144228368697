import { inject, Injectable } from '@angular/core'
import { ConfirmDialogComponent, deepEqual, DialogService } from '@mediacoach/ui'
import { DefaultProjectorFn, MemoizedSelector, Store } from '@ngrx/store'
import { Observable, OperatorFunction, throwError } from 'rxjs'
import { MergedTokens } from '@core/state/models/merged-tokens.model'
import { catchError, filter, map, switchMap } from 'rxjs/operators'
import { DialogUpdatePlaylistClipCommentComponent } from '@features/playlist/dialogs/dialog-update-playlist-clip-comment/components/dialog-update-playlist-clip-comment.component'
import {
  acceptConfirmDeletePlaylistClipDialog,
  setIsDeletingTagItem,
  successDeletePlaylistClip,
  updatePlaylistClip,
} from '@core/state/actions/stream-playlist-clip.merged-actions'
import { PlaylistApi } from '@core/requests/api/playlist/playlist.api'
import { BaseEffectsHandler } from '@core/models/base-classes/base-effects.handler'
import { getTagRequestItem } from '@core/state/utils/playlist-clip.utils'
import {
  fetchTags,
  resetFilterValuesAndActive,
  setPlaylistLoader,
  setSelectedTagItem,
} from '@core/state/actions/stream-playlist.merged-actions'
import { concatLatestFrom } from '@ngrx/operators'
import {
  selectCurrentSelectedPlaylist,
  selectHasFiltersActive,
  selectParsedTagItems,
} from '@core/state/selectors/stream-playlist.merged-selectors'

@Injectable({ providedIn: 'root' })
export class PlaylistClipEffectsHandler extends BaseEffectsHandler {
  protected readonly _store = inject(Store)
  protected readonly _dialog = inject(DialogService)
  protected readonly _api = inject(PlaylistApi)

  openConfirmDeletePlaylistClipDialog(_identifier: MergedTokens): OperatorFunction<any, any> {
    return (source): Observable<any> =>
      source.pipe(
        switchMap(({ displayMode, playlistClipToDelete }) =>
          this._translate
            .get([
              'MTR_PLAYLIST_DELETE_TAG_MODAL_TITLE',
              'MTR_PLAYLIST_DELETE_TAG_MODAL_MESSAGE',
              'MTR_COMMON_DELETE',
              'CONTACT_FORM_BTN_CANCEL',
            ])
            .pipe(
              map(
                ({
                  MTR_PLAYLIST_DELETE_TAG_MODAL_TITLE: header,
                  MTR_PLAYLIST_DELETE_TAG_MODAL_MESSAGE: message,
                  MTR_COMMON_DELETE: acceptButtonText,
                  CONTACT_FORM_BTN_CANCEL: cancelButtonText,
                }) => ({
                  header,
                  message,
                  acceptButtonText,
                  cancelButtonText,
                  displayMode,
                  playlistClipToDelete,
                }),
              ),
            ),
        ),
        switchMap(
          ({
            header,
            message,
            acceptButtonText,
            cancelButtonText,
            displayMode,
            playlistClipToDelete,
          }) =>
            this._dialog
              .open(ConfirmDialogComponent, {
                header,
                styleClass: `mcp-playlist-dialog ${
                  displayMode === 'modal' ? 'mcp-playlist-dialog--dark m-app--dark' : ''
                }`,
                data: { message, acceptButtonText, cancelButtonText },
              })
              .onClose.pipe(
                filter((accept) => accept === true),
                map(() => playlistClipToDelete),
              ),
        ),
        map((payload) => acceptConfirmDeletePlaylistClipDialog(_identifier)(payload)),
      )
  }

  acceptConfirmDeletePlaylistClipDialog(
    _identifier: MergedTokens,
    _selector: MemoizedSelector<object, any, DefaultProjectorFn<any>>,
  ): OperatorFunction<any, any> {
    return (source): Observable<any> =>
      source.pipe(
        concatLatestFrom(() => [this._store.select(selectCurrentSelectedPlaylist(_selector))]),
        switchMap(([{ payload }, selectedPlaylist]) =>
          this._api.deleteTag(selectedPlaylist.id, payload['id']).pipe(
            this.handleRequest(
              [
                fetchTags(_identifier)({ matchId: payload.matchId }),
                successDeletePlaylistClip(_identifier)(payload),
              ],
              'MTR_TAGGING_TOAST_DELETE_TAG_SUCCESS',
              'MTR_TAGGING_TOAST_DELETE_TAG_FAILURE',
            ),
            catchError((err) => {
              this._store.dispatch(setPlaylistLoader(_identifier)({ playlistLoader: false }))
              this._store.dispatch(fetchTags(_identifier)({ matchId: payload.matchId }))
              return throwError(() => err)
            }),
          ),
        ),
      )
  }

  successDeletePlaylistClip(
    _identifier: MergedTokens,
    _selector: MemoizedSelector<object, any, DefaultProjectorFn<any>>,
  ): OperatorFunction<any, any> {
    return (source): Observable<any> =>
      source.pipe(
        concatLatestFrom(() => [
          this._store.select(selectParsedTagItems(_selector)),
          this._store.select(selectHasFiltersActive(_selector)),
        ]),
        switchMap(([{ payload }, parsedItems, filtersActive]) => {
          const allItems = [...parsedItems]
          let currentIndex = payload['idx']
          allItems.splice(currentIndex, 1)
          if (currentIndex > allItems.length - 1) {
            currentIndex = currentIndex - 1
          }
          const iText = String(currentIndex + 1)
          const indexText = iText.length === 1 ? `0${iText}` : iText
          const selectedTagItem = {
            ...allItems[currentIndex],
            index: indexText,
            idx: currentIndex,
          }
          if (!allItems.length && filtersActive) {
            return [resetFilterValuesAndActive(_identifier)()]
          } else {
            return [
              setIsDeletingTagItem(_identifier)({ isDeletingTagItem: true }),
              selectedTagItem.idx === -1
                ? setSelectedTagItem(_identifier)({ selectedTagItem: null })
                : setSelectedTagItem(_identifier)({ selectedTagItem }),
            ]
          }
        }),
      )
  }

  openEditPlaylistClipCommentDialog(_identifier: MergedTokens): OperatorFunction<any, any> {
    return (source): Observable<any> =>
      source.pipe(
        switchMap(({ displayMode, playlistClip }) =>
          this._translate
            .get('MTR_PLAYLIST_EDIT_TAG_ITEM_COMMENT_TITLE')
            .pipe(map((header) => ({ header, displayMode, playlistClip }))),
        ),
        switchMap(({ header, displayMode, playlistClip }) =>
          this._dialog
            .open(DialogUpdatePlaylistClipCommentComponent, {
              header,
              styleClass: `mcp-playlist-dialog mcp-playlist-clip-comment-dialog ${
                displayMode === 'modal' ? 'mcp-playlist-dialog--dark m-app--dark' : ''
              }`,
              data: playlistClip,
            })
            .onClose.pipe(filter((result) => !deepEqual(result, { closed: true }))),
        ),
        map((payload) => updatePlaylistClip(_identifier)(payload)),
      )
  }

  updatePlaylistClip(_identifier: MergedTokens): OperatorFunction<any, any> {
    return (source): Observable<any> =>
      source.pipe(
        switchMap(({ payload }) =>
          this._api.updateTag(payload.matchId, payload.id, getTagRequestItem(payload)).pipe(
            this.handleRequest(
              [
                fetchTags(_identifier)({ matchId: payload.matchId }),
                setSelectedTagItem(_identifier)({ selectedTagItem: payload }),
              ],
              'MTR_TAGGING_TOAST_UPDATE_PLAYLIST_SUCCESS',
              'MTR_TAGGING_TOAST_UPDATE_PLAYLIST_FAILURE',
            ),
            catchError((err) => {
              this._store.dispatch(setPlaylistLoader(_identifier)({ playlistLoader: false }))
              this._store.dispatch(fetchTags(_identifier)({ matchId: payload.matchId }))
              return throwError(() => err)
            }),
          ),
        ),
      )
  }
}
