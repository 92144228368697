import { FilterItem } from '@features/playlist/models/playlist.models'
import { FilterType, TagVideoType } from '@features/playlist/enums/playlist.enums'
import { Period, VideoType } from '@mediacoach-ui-library/global'

export const DEFAULT_ALL_FILTERS: FilterItem[] = [
  {
    id: 'all',
    value: 'all',
    label: 'MTR_PLAYLIST_FILTER_ALL',
  },
  {
    id: 'type.id',
    value: '1',
    label: 'MTR_PLAYLIST_FILTER_TAGGING',
  },
  {
    id: 'type.id',
    value: '2',
    label: 'MTR_PLAYLIST_FILTER_WIDGETS_EVENTS',
  },
  {
    id: 'codeSnapshot.name',
    value: 'MTR_TAGGING_QUICK_TAG',
    label: 'MTR_TAGGING_QUICK_TAG',
  },
  {
    id: 'isHighlighted',
    value: 'true',
    icon: 'star-rounded',
    styleClass: 'is-highlighted-icon',
  },
]

export const DEFAULT_OTHER_FILTERS: FilterItem[] = [
  {
    id: 'periodNumber',
    value: '1',
    label: 'MTR_PLAYLIST_FILTER_OTHER_FIRST_HALF',
  },
  {
    id: 'periodNumber',
    value: '2',
    label: 'MTR_PLAYLIST_FILTER_OTHER_SECOND_HALF',
  },
  {
    id: 'hasComment',
    value: 'true',
    label: 'MTR_PLAYLIST_FILTER_OTHER_COMMENT',
  },
]

export const ACTIVE_FILTERS_TYPE = [
  FilterType.Other,
  FilterType.Player,
  FilterType.Team,
  FilterType.Event,
]

export const GET_VIDEO_ID_BY_VIDEO_TYPE = {
  [VideoType.Tv]: TagVideoType.Tv,
  [VideoType.Panoramic]: TagVideoType.Pan,
  [VideoType.Tactical]: TagVideoType.Tac,
  [VideoType.Live]: TagVideoType.Live,
  undefined: TagVideoType.undefined,
}

export const GET_PERIOD_NUMBER_BY_PERIOD_TYPE = {
  [Period.First]: 1,
  [Period.Second]: 2,
  [Period.ExtraFirst]: 3,
  [Period.ExtraSecond]: 4,
  undefined: 0,
}

export const DEFAULT_ERROR_TAGS = {
  results: [],
  pager: { count: 0, limit: 10, offset: 0, page: 0 },
}

export const PLAYLIST_SORT_OPTIONS: FilterItem[] = [
  {
    id: 'sort',
    value: 'elapsedTime',
    label: 'MTR_TAGGING_CHRONOLOGICAL_ORDER_ENABLED',
  },
  {
    id: 'sort',
    value: 'custom',
    label: 'MTR_TAGGING_PERSONALIZED_ORDER_ENABLED',
  },
]

export const DEFAULT_SORT_OPTION: FilterItem = {
  id: 'sort',
  value: 'elapsedTime',
  label: 'MTR_TAGGING_CHRONOLOGICAL_ORDER_ENABLED',
}
