import {
  McpFormattedMetricsConfig,
  McpMetricListSetup,
  McpMetricSelected,
  McpMetricsMetadata,
  McpSpiderChartSetup,
} from '@core/models/dto/metric.dto'
import { ApiMetric } from '@core/models/models/metric.models'
import { TeamSummary } from '@features/team-details/models/team-details-summary.models'
import {
  FormattedMetricsConfig,
  GetLeftRightValue,
  LA_LIGA_LIST_NAME,
  List,
  ListHeaderContent,
  ListType,
  ParseMetricResults,
  ParseSpiderChartData,
  PLACEHOLDER_IMAGES,
  SafeObjectData,
} from '@mediacoach-ui-library/global'
import { DEFAULT_MATCH_SWITCHER } from '@core/constants/matches.constants'
import { PlayerSummary } from '@core/models/dto/player.dto'
import { PLAYER_AVERAGE_BY_POSITION } from '@core/constants/player.constants'
import { areArrayValuesEmpty } from '@core/utils/collection.utils'
import { MetricAggregation } from '@core/enums/metric.enums'

export const parseLeftRightMetrics = (arr) =>
  arr.map(({ leftAverageMetricResult, rightAverageMetricResult }) => [
    leftAverageMetricResult,
    rightAverageMetricResult,
  ])

const buildLeftContent = (config: FormattedMetricsConfig): ListHeaderContent => ({
  ...(config?.left || ({} as any)),
  title: config.isSwitchable ? null : config?.left?.title,
  subtitle: config.isSwitchable
    ? null
    : config?.left?.subtitle
      ? config?.left?.subtitle
      : config?.fromMatch
        ? 'MTR_COMMON_MATCH_VALUE'
        : 'MTR_COMMON_AVERAGE',
})

const buildRightContent = (
  config: FormattedMetricsConfig,
  competitionImg: string,
  competitionName: string = LA_LIGA_LIST_NAME,
): ListHeaderContent => ({
  ...(config?.right || ({} as any)),
  img: config?.fromMatch ? config?.right?.img : competitionImg || PLACEHOLDER_IMAGES.COMPETITION,
  title: config.isSwitchable ? null : config.fromMatch ? config?.right?.title : competitionName,
  subtitle: config?.isSwitchable
    ? null
    : config?.right?.subtitle
      ? config?.right?.subtitle
      : 'MTR_COMMON_AVERAGE',
})

const buildMetricGroups = (
  config: FormattedMetricsConfig,
  aggregation: MetricAggregation,
  metricKeys: string[],
) =>
  SafeObjectData(config.metricsProperty, 'groups', []).map((group) => ({
    title: SafeObjectData(group, 'key', null),
    items: SafeObjectData(group, aggregation, []).map((matchValue) => ({
      text: SafeObjectData(matchValue, 'key', ''),
      ...GetLeftRightValue(matchValue, metricKeys),
    })),
  }))

export const buildMetrics = (
  config: FormattedMetricsConfig,
  aggregation: MetricAggregation,
  metricKeys: string[],
  competitionImg: string,
  competitionName?: string,
) => ({
  type: ListType.Metrics,
  header: {
    leftContent: buildLeftContent(config),
    rightContent: buildRightContent(config, competitionImg, competitionName),
    isSwitchable: config.isSwitchable,
    switcherValues: config.isSwitchable ? DEFAULT_MATCH_SWITCHER : null,
  },
  groups: buildMetricGroups(config, aggregation, metricKeys),
})

export const mapMetricsAsList = (
  config: McpFormattedMetricsConfig,
  competitionImg?: string,
  competitionName?: string,
  matchValuesHeader?: any,
  averageValuesHeader?: any,
  accumulatedValuesHeader?: any,
) => {
  const averageKeys = config.averageValueKeys || [
    'leftAverageMetricResult',
    'rightAverageMetricResult',
  ]
  const matchKeys = config.matchValueKeys || ['leftMatchMetricResult', 'rightMatchMetricResult']
  const accumulatedKeys = config.accumulatedValueKeys || [
    'leftAccumulatedMetricResult',
    'rightAccumulatedMetricResult',
  ]
  const id = SafeObjectData(config.metricsProperty, 'key')

  const avgMetrics = buildMetrics(
    config,
    MetricAggregation.AverageValues,
    averageKeys,
    competitionImg,
    competitionName,
  )

  return {
    id,
    label: id,
    isSwitchable: config.isSwitchable,
    metrics:
      !config.isSwitchable && !matchValuesHeader
        ? avgMetrics
        : {
            matchValues: {
              ...buildMetrics(
                config,
                MetricAggregation.MatchValues,
                matchKeys,
                competitionImg,
                competitionName,
              ),
              ...(matchValuesHeader ? { header: matchValuesHeader } : {}),
            },
            averageValues: {
              ...avgMetrics,
              ...(averageValuesHeader ? { header: averageValuesHeader } : {}),
            },
            accumulatedValues: {
              ...buildMetrics(
                config,
                MetricAggregation.AccumulatedValues,
                accumulatedKeys,
                competitionImg,
                competitionName,
              ),
              ...(accumulatedValuesHeader ? { header: accumulatedValuesHeader } : {}),
            },
          },
  }
}

export const mapMetrics = (
  { summary, metrics: teamMetrics }: McpMetricsMetadata<TeamSummary | PlayerSummary>,
  isPlayer: boolean,
  competitionImg: string,
  competitionName?: string,
): { chart: McpSpiderChartSetup; list: McpMetricListSetup } => {
  const { metricPlayerName, playerPosition } = summary as PlayerSummary
  const { name } = summary as TeamSummary
  const config: McpFormattedMetricsConfig = {
    left: {
      img: summary.portraitLogo,
      title: metricPlayerName || name,
      isPlayer,
    },
    right: {
      img: summary.portraitLogo,
      title: summary.name,
      subtitle: !!playerPosition && PLAYER_AVERAGE_BY_POSITION[playerPosition],
    },
  }
  const { metrics: chartMetrics }: { metrics: List<ApiMetric> } = mapMetricsAsList(
    {
      ...config,
      metricsProperty: ParseMetricResults(teamMetrics.chartMetricResults),
    },
    competitionImg,
    competitionName,
  ) as { metrics: List<ApiMetric> }

  const { metrics: overview }: { metrics: List<ApiMetric> } = mapMetricsAsList(
    {
      ...config,
      metricsProperty: teamMetrics.metricsOverview as {
        key: string
        groups: any[]
      },
    },
    competitionImg,
    competitionName,
  ) as { metrics: List<ApiMetric> }

  const chart = {
    spiderChart: ParseSpiderChartData(teamMetrics),
    isSpiderChartEmpty: areArrayValuesEmpty(parseLeftRightMetrics(teamMetrics.chartMetricResults)),
    spiderChartHeader: chartMetrics.header,
    chartMetrics,
  }

  const list = {
    isMetricListEmpty: areArrayValuesEmpty(
      teamMetrics.metricsOverview.groups.map(({ averageValues }) =>
        parseLeftRightMetrics(averageValues),
      ),
    ),
    metricList: overview,
  }

  return { chart, list }
}

export const mapSelectedMetric = (
  key: string,
  { header, ...metrics }: List<ApiMetric>,
  summary: TeamSummary | PlayerSummary,
  t?: { competitionId?: string; name: string; portraitLogo: string },
): McpMetricSelected => {
  const { metricPlayerName, team } = summary as PlayerSummary
  const { name, portraitLogo } = summary as TeamSummary
  const teamData = t || team || ({} as PlayerSummary)
  const items = SafeObjectData(metrics, 'groups.0.items', [])
  const [filterItem] = items
    .filter(({ text }) => text === key)
    .map((item) => ({
      ...item,
      text: null,
      homeValue: item.homeLabel,
      awayValue: item.awayLabel,
      homeDecorated: false,
      awayDecorated: false,
    }))

  return {
    dualData: { ...metrics, header, groups: [{ items: [filterItem] }] },
    metricLabel: key,
    competition: summary.competition,
    imgUrl: SafeObjectData(header, 'leftContent.img', ''),
    name: SafeObjectData(header, 'leftContent.title', ''),
    nameWithShirtNumber: metricPlayerName,
    team: {
      name: teamData.name || name,
      portraitLogo: teamData.portraitLogo || portraitLogo,
    },
  }
}
