import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { collapseHorizontallyAnimation } from '@shared/animations/animations/collapse.animation'

@Component({
  selector: 'mcp-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [collapseHorizontallyAnimation()],
})
export class SidebarComponent {
  @Input() collapsed = true

  toggle(): void {
    this.collapsed = !this.collapsed
  }
}
