export const GA_LITERALS_FOR_ASSET_TITLES = {
  Tv: {
    action: 'media_tv',
    label: 'tv',
  },
  installer_DB: {
    action: 'installer_DB',
    label: 'installer db',
  },
  installer: {
    action: 'installer',
    label: 'installer',
  },
  installer_desktop_win: {
    action: 'installer_desktop_win',
    label: 'installer',
  },
  installer_desktop_mac: {
    action: 'installer_desktop_mac',
    label: 'installer',
  },
  installer_ios: {
    action: 'installer_ios',
    label: 'installer IOS',
  },
  installer_android: {
    action: 'installer_android',
    label: 'installer Android',
  },
  manual_statistical_report: {
    action: 'manual_statistical_report',
    label: 'Manual Statistical reports',
  },
  manual_en: {
    action: 'manual_en',
    label: 'english manual',
  },
  manual_es: {
    action: 'manual_es',
    label: 'spanish manual',
  },
  installation_manual_desktop_win_en: {
    action: 'installation_manual_desktop_win_en',
    label: 'english install manual windows',
  },
  installation_manual_desktop_win_es: {
    action: 'installation_manual_desktop_win_es',
    label: 'spanish install manual windows',
  },
  installation_manual_desktop_mac_en: {
    action: 'installation_manual_desktop_mac_en',
    label: 'english install manual macOS',
  },
  installation_manual_desktop_mac_es: {
    action: 'installation_manual_desktop_mac_es',
    label: 'spanish install manual macOS',
  },
  team_viewer_es: {
    action: 'team_viewer_es',
    label: 'spanish team viewer',
  },
  dongle_driver: {
    action: 'dongle_driver',
    label: 'dongle driver',
  },
  dongle_driver_win: {
    action: 'dongle_driver_win',
    label: 'dongle driver windows',
  },
  dongle_driver_mac: {
    action: 'dongle_driver_mac',
    label: 'dongle driver mac',
  },
  team_viewer_en: {
    action: 'team_viewer_en',
    label: 'english team viewer',
  },
  manual_physical_report: {
    action: 'manual_physical_report',
    label: 'Manual Supplementary physical report',
  },
  Panoramic: {
    action: 'media_pana',
    label: 'panoramic',
  },
  Tac: {
    action: 'tactical_camera',
    label: 'tactical camera',
  },
  ReportPhy: {
    action: 'physical_report',
    label: 'physical report',
  },
  ReportTeamAdvancePreMatch: {
    action: 'advance_pre_match',
    label: 'advance pre match',
  },
  ReportMedia: {
    action: 'media_report',
    label: 'media report',
  },
  ReportTeamPreMatch: {
    action: 'pre_match',
    label: 'pre match',
  },
  ReportTeamPostMatch: {
    action: 'post_match_team',
    label: 'post match team',
  },
  ReportPlayersPostMatch: {
    action: 'post_match_player',
    label: 'post match player',
  },
  wimu_manual: {
    action: 'wimu_manual',
    label: 'Wimu Manual',
  },
  WarmUp: {
    action: 'media_warm_up',
    label: 'warm up',
  },
  mobile_installer_ios: {
    action: 'mobile_installer_ios',
    label: 'Mobile Installer iOS',
  },
  mobile_installer_android: {
    action: 'mobile_installer_android',
    label: 'Mobile Installer Android',
  },
  installation_manual_mobile_ios: {
    action: 'installation_manual_mobile_ios',
    label: 'Installation Manual Mobile iOS',
  },
  installation_manual_mobile_android: {
    action: 'installation_manual_mobile_android',
    label: 'Installation Manual Mobile Android',
  },
  user_manual_mobile: {
    action: 'user_manual_mobile',
    label: 'User Manual Mobile',
  },
  livepro_installer_ios: {
    action: 'livepro_installer_ios',
    label: 'LivePro Installer iOs',
  },
  installation_manual_livepro_ios: {
    action: 'installation_manual_livepro_ios',
    label: 'Installation Manual LivePro iOs',
  },
  power_bi_wcs_manual: {
    action: 'power_bi_wcs_manual',
    label: 'Power Bi WCS Manual',
  },
  wcs_manual: {
    action: 'wcs_manual',
    label: 'WCS Manual',
  },
  beyond_stats_manual: {
    action: 'beyond_stats_manual',
    label: 'Beyond Stats Manual',
  },
  mediacoach_analysis_manual: {
    action: 'mediacoach_analysis_manual',
    label: 'Mediacoach Analysis Manual',
  },
  venue_doc_ll_01: {
    action: 'stadiums_documentation_laliga_ea_sports',
    label: 'Stadiums LaLiga EA Sports',
  },
  venue_doc_ll_02: {
    action: 'stadiums_documentation_laliga_hypermotion',
    label: 'Stadiums LaLiga HyperMotion',
  },
  mediacoach_demarcations_manual: {
    action: 'mediacoach_demarcations_manual',
    label: 'Mediacoach Demarcations Manual',
  },
}

export enum AssetContentType {
  Tac = 'Tac',
  Panoramic = 'Panoramic',
  Tv = 'Tv',
  WarmUpH = 'WarmUpH',
  WarmUpA = 'WarmUpA',
  ReportMedia = 'ReportMedia',
}

export const GTM_TAG_DICTIONARY = {
  [AssetContentType.Tac]: 'tactical',
  [AssetContentType.Panoramic]: 'panoramic',
  [AssetContentType.Tv]: 'television',
  [AssetContentType.WarmUpH]: 'WarmUpH',
  [AssetContentType.WarmUpA]: 'WarmUpA',
}
