import { createSelector, DefaultProjectorFn, MemoizedSelector, select } from '@ngrx/store'
import { StreamState } from '@core/state/models/stream.state'
import { pipe } from 'rxjs'
import { filter, map, share } from 'rxjs/operators'
import { PlayerHeaderData } from '@features/match-tabs/containers/match-player/components/match-player-header/models/match-player-header.models'
import { getLiveMatches, getTopic } from '@core/state/selectors/socket.selectors'
import { Topic } from '@sockets/enums/socket.enum'
import { parseMatchTeamMetrics, parseMatchTeamSegmentOptions } from '@core/utils/team.utils'
import { getMatchIdByLocation, mapComparableMatch } from '@core/utils/match.utils'
import { updateListItemAttributes } from '@core/analytics/utils/analytics.utils'
import { Match } from '@core/models/dto/match.dto'
import { SegmentOption } from '@shared/components/segmented-control/segmented-control.models'
import { AGGREGATED_METRIC_LABEL_MAP } from '@core/constants/metric-aggregation.constants'

export const getMatch = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(feature, (state) => state?.currentMatch)

export const getMatchStream = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(feature, (state) => state.stream)

export const getTimelineConfig = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(feature, (state) => state.timelineConfig)

export const getTimelineConfigPeriods = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(feature, (state) => state?.timelineConfig?.periods)

export const isPlayListTabSelected = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(feature, (state) => state?.isPlaylistTabSelected)

export const getPassMatrix = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(feature, (state) => state.passMatrix)

export const selectPassMatrixLoader = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(feature, (state) => state.passMatrixLoader)

export const getHeatMap = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(feature, (state) => state.heatMap)

export const selectHeatMapLoader = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(feature, (state) => state.heatMapLoader)

export const getTimelineLoader = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(feature, (state) => state.timelineLoader)

export const getMatchLoader = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(feature, (state) => state.matchLoader)

export const getPlayerLoader = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(feature, (state) => state.playerLoader)

export const getComparisonLoader = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(feature, (state) => state.comparisonLoader)

export const getMatchPlayerMetricsLoader = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(feature, (state) => state.playerMetricsLoader)

export const getExportPDFLoader = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(feature, (state) => state.pdfLoader)

export const getMatchStreamType = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(feature, (state) => state?.streamType)

export const getMatchTeamMetrics = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(feature, (state) => state.teamMetrics)

export const selectTeamMetricsLoader = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(feature, (state) => state.teamMetricsLoader)

export const getPlayerMetrics = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(feature, (state) => state.playerMetrics)

export const getHasLineup = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(getMatch(feature), (match) => !!match?.lineup?.home?.values?.length)

export const selectMatchSelectedPlayer = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(feature, (state) => state.comparison || state.selectedPlayer)

export const selectIsMatchPlayerSelected = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectMatchSelectedPlayer(feature), (player) => !!player)

export const selectSelectedPlayerAggregationMode = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectMatchSelectedPlayer(feature), (player) => player?.aggregationMode)

export const selectSelectedPlayerAggregationModes = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectMatchSelectedPlayer(feature), (player) => player?.aggregationModes)

export const selectSelectedPlayerAggregationLabel = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) =>
  createSelector(
    selectSelectedPlayerAggregationMode(feature),
    (mode) => mode && AGGREGATED_METRIC_LABEL_MAP[mode],
  )

export const selectSelectedPlayerAggregationHeader = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectSelectedPlayerAggregationLabel(feature), (text) => ({ text }))

export const selectMatchAggregationMode = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(feature, (state) => state?.aggregationMode)

export const selectMatchAggregationModes = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(feature, (state) => state?.aggregationModes)

export const selectMatchAggregationLabel = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) =>
  createSelector(
    selectMatchAggregationMode(feature),
    (mode) => mode && AGGREGATED_METRIC_LABEL_MAP[mode],
  )

export const selectMatchAggregationHeader = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectMatchAggregationLabel(feature), (text) => ({ text }))

export const getMatchTeamSegmentOptions = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) =>
  createSelector(getMatch(feature), (match) =>
    match ? parseMatchTeamSegmentOptions(match) : undefined,
  )

export const getParsedMatchTeamMetrics = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) =>
  createSelector(getMatch(feature), getMatchTeamMetrics(feature), (match, metrics) =>
    match && metrics ? parseMatchTeamMetrics(match, metrics) : undefined,
  )

export const getComparison = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) =>
  createSelector(feature, (state) =>
    state && state.selectedPlayer && state.currentMatch
      ? state.comparison ||
        ({
          playerA: state.selectedPlayer as any,
        } as PlayerHeaderData)
      : undefined,
  )

export const getComparableMatch = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) =>
  createSelector(feature, (state) =>
    state && state.currentMatch ? mapComparableMatch(state.currentMatch) : undefined,
  )

export const getGTMAttributeAwareSetupAndMatch = (
  feature: MemoizedSelector<object, StreamState, DefaultProjectorFn<StreamState>>,
  setup: SegmentOption[],
) =>
  pipe(
    select(getMatch(feature)),
    map((match) => ({
      setup: match ? updateListItemAttributes([...setup], {}) : [...setup],
      match,
    })),
  )
export const getLiveMatch = () =>
  pipe(
    select(createSelector(getLiveMatches, (streaming) => streaming)),
    map((streaming) => (streaming || []).find((m) => m.id === getMatchIdByLocation())),
    filter((updateMatch) => !!updateMatch),
  )

export const getMatchChanged = () =>
  pipe(
    select(createSelector(getTopic(Topic.MatchChanged), (match: Match) => match)),
    filter((match) => !!match && match.id === getMatchIdByLocation()),
  )

const _getMarkers = (
  feature: MemoizedSelector<object, StreamState, DefaultProjectorFn<StreamState>>,
) => createSelector(feature, (state) => state.markers)

export const getMatchMarkers = (
  feature: MemoizedSelector<object, StreamState, DefaultProjectorFn<StreamState>>,
) =>
  pipe(
    select(
      createSelector(
        getMatchStreamType(feature),
        _getMarkers(feature),
        (streamType, markersGroup) => ({
          streamType,
          markersGroup,
        }),
      ),
    ),
    filter(({ streamType, markersGroup }) => !!streamType && !!markersGroup),
    map(({ streamType, markersGroup }) => markersGroup[streamType.videoType] || []),
  )

export const getCurrentMatchWithTimelinePeriods = (
  feature: MemoizedSelector<object, StreamState, DefaultProjectorFn<StreamState>>,
) =>
  pipe(
    select(
      createSelector(getMatch(feature), getTimelineConfigPeriods(feature), (match, periods) => ({
        match,
        periods,
      })),
    ),
    filter(({ match, periods }) => !!match && !!periods),
    share(),
  )
