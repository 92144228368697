<app-loader [loading]="loading || teamLoading">
  <div [attr.data-cy]="'last-matches'" class="widget-last-matches">
    <app-title title="COMMONS_TITLE_LAST_MATCHES" [size]="titleSize"></app-title>
    <div class="box-wrapper">
      <mc-box [hasDecoration]="false" [class.scrollable]="!isNotDesktop" [modifers]="boxModifiers">
        <mcp-match-item-list-container
          *ngIf="lastMatches?.length > 0; else notAvailableContent"
          [loading]="!lastMatches"
          [matches]="lastMatches"
          [config]="tableConfig"
          [matchItemTemplates]="matchItemConfig"
          (matchesSelected)="matchesSelected.emit($event)"
        >
        </mcp-match-item-list-container>
      </mc-box>
    </div>
    <ng-template #notAvailableContent>
      <mcp-unavailable-content [competition]="competition"></mcp-unavailable-content>
    </ng-template>
  </div>
</app-loader>
