import { ChangeDetectionStrategy, Component } from '@angular/core'
import { DialogConfig } from '@mediacoach/ui'
import { Observable } from 'rxjs'
import { SORT_ICON_CONFIG } from '../../constants/widget.constants'
import { fadeInSlideAnimation } from '@widgets/animations/fade-in-slide.animation'

@Component({
  selector: 'mcp-dialog-metric-ranking',
  templateUrl: './dialog-metric-ranking.component.html',
  styleUrl: './dialog-metric-ranking.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInSlideAnimation],
})
export class DialogMetricRankingComponent {
  metricRanking$: Observable<any>

  readonly sortIconConfig = SORT_ICON_CONFIG

  get dialogData() {
    return this._dialogConfig?.data
  }

  constructor(private readonly _dialogConfig: DialogConfig) {
    this.metricRanking$ = this.dialogData?.metricRanking$
  }
}
