import { ACTIVE_FILTERS_TYPE } from '@features/playlist/constants/playlist.constants'
import { SafeObjectData } from '@mediacoach-ui-library/global'
import { FilterType } from '@features/playlist/enums/playlist.enums'
import { MatchTeamData } from '@core/models/dto/match.dto'

export const hasFiltersActive = (filterValues) =>
  !!(
    ACTIVE_FILTERS_TYPE.some((type) => SafeObjectData(filterValues, [type], []).length) ||
    SafeObjectData(filterValues, [FilterType.All], []).filter((item) => item.id !== FilterType.All)
      ?.length
  ) as boolean

export const getTeamPlayers = (team: MatchTeamData) =>
  team.squad && team.squad.length > 0 ? team.squad : team.lineup || []

export const translateTagName = (codeSnapshot, translations) => {
  if (!codeSnapshot) {
    return ''
  }
  if (codeSnapshot.category) {
    const localizedCategory = translations[codeSnapshot.category]?.toUpperCase()
    if (codeSnapshot.subCategory) {
      return `${localizedCategory || codeSnapshot.category} - ${translations[codeSnapshot.subCategory] || codeSnapshot.subCategory}`
    }
    return localizedCategory || codeSnapshot.category
  }
  return translations[codeSnapshot.name]?.toUpperCase() || codeSnapshot.name
}
