export enum WidgetIdentifier {
  sortableMetrics = 'sortableMetrics',
  top5Metrics = 'top5Metrics',
  bottom5Metrics = 'bottom5Metrics',
  playerRankedMetric = 'playerRankedMetric',
  goalkeeperRankedMetric = 'goalkeeperRankedMetric',
  freeMetrics = 'freeMetrics',
  competitions = 'competitions',
  goalkeeperCompetitions = 'goalkeeperCompetitions',
  manager = 'manager',
  trend = 'trend',
  teamMatches = 'teamMatches',
  squad = 'squad',
  goalkeepers = 'goalkeepers',
  playerStats = 'playerStats',
  goalkeeperStats = 'goalkeeperStats',
  teamStats = 'teamStats',
  teamRankedMetric = 'teamRankedMetric',
  teamTopPlayers = 'teamTopPlayers',
  leaderboard = 'leaderboard',
  honors = 'honors',
  transfers = 'transfers',
  trajectory = 'trajectory',
  reports = 'reports',
  competitionMatches = 'competitionMatches',
  competitionTopPlayers = 'competitionTopPlayers',
}
