import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core'

import {
  ConnectionCoordinateType,
  FieldDiagramModifier,
  FieldDirection,
  IFieldDiagramData,
  TeamType,
} from '@mediacoach-ui-library/global'

import { debounceTime, map, tap } from 'rxjs/operators'

import { FieldDiagramDefaultOptions } from './field-diagram.constants'
import { SegmentModifier, SegmentOption } from '../segmented-control/segmented-control.models'

@Component({
  selector: 'app-field-diagram',
  templateUrl: './field-diagram.component.html',
  styleUrls: ['./field-diagram.component.scss'],
})
export class FieldDiagramComponent {
  private _data: IFieldDiagramData

  get data(): IFieldDiagramData {
    return this._data
  }
  @Input() set data(_data: IFieldDiagramData) {
    this._data = null
    const selectedPlayerNumber = this.selectedPlayerNumber
    this.selectedPlayerNumber = null
    this._ref.detectChanges()
    this.selectedPlayerNumber = selectedPlayerNumber
    this._data = _data
    this.onPlayerChange.emit(null)
  }

  @Input() loading = false
  @Input() showPopUp
  @Input() title
  @Input() type: TeamType = TeamType.Home
  @Input() resetWhenClickOutside = true
  @Input() options: SegmentOption[] = [...FieldDiagramDefaultOptions]
  @Input() segmentValue: string = FieldDiagramDefaultOptions[0].id
  @Input() isColored: boolean
  @Input() playerPopUpTemplate: TemplateRef<any>
  @Input() arrowDirection: FieldDirection = FieldDirection.Right
  @Input() connectionCoordinateType: ConnectionCoordinateType = ConnectionCoordinateType.Target
  @Input() selectedPlayerNumber = null
  @Input() keepPlayerSelected = false

  @Output() onChange: EventEmitter<string> = new EventEmitter<string>()
  @Output() onPlayerChange: EventEmitter<number | string> = new EventEmitter<number | string>()

  selectedPlayer$ = this.onPlayerChange.pipe(
    debounceTime(0),
    map((player) => !!player),
  )
  connectionCoordinateChange$ = this.onChange.pipe(
    debounceTime(0),
    tap((type: ConnectionCoordinateType) => (this.connectionCoordinateType = type)),
  )
  FieldDiagramModifier = FieldDiagramModifier
  SegmentModifier = SegmentModifier

  constructor(private _ref: ChangeDetectorRef) {}
}
