import { FilterItem, TagCode } from '@features/playlist/models/playlist.models'
import {
  PlaylistResponse,
  PlaylistsResponse,
  TagListResponse,
  TagResponse,
} from '@features/playlist/models/playlist.response.models'
import { TagItem } from '@mediacoach/ui'
import { FilterType } from '@features/playlist/enums/playlist.enums'
import { PartialRecord } from '@core/types/record.types'
import { createAction, props } from '@ngrx/store'
import { MergedTokens } from '@core/state/models/merged-tokens.model'

export const fetchPlayLists = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] fetch playlists`, props<{ matchId: string }>())
export const setPlaylists = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] set playlists`,
    props<{ playlists: PlaylistsResponse }>(),
  )
export const setSelectedPlaylist = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] set selected playlist index`,
    props<{ matchId: string; selectedPlaylist: PlaylistResponse }>(),
  )
export const fetchPlayListData = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] fetch playlist data`, props<{ matchId: string }>())
export const fetchFiltersData = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] fetch filters data`, props<{ matchId: string }>())
export const fetchTags = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] fetch tags`, props<{ matchId: string }>())
export const setTags = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] set tags`, props<{ tags: TagListResponse }>())
export const parseTags = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] parse tags`, props<{ results: TagResponse[] }>())
export const setParsedTagItems = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] set parsed items`,
    props<{ parsedTagItems: TagItem[] }>(),
  )
export const fetchDimensions = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] fetch dimensions`,
    props<{ matchId: string; playlistId: string }>(),
  )
export const setDimensions = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] set dimensions`, props<{ dimensions: string[] }>())
export const fetchCodes = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] fetch codes`,
    props<{ matchId: string; playlistId: string }>(),
  )
export const setCodes = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] set codes`, props<{ codes: TagCode[] }>())
export const openFiltersModal = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] open filters modal`, props<{ displayMode: string }>())
export const setPlaylistLoader = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] set playlist loader`,
    props<{ playlistLoader: boolean }>(),
  )
export const downloadXml = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] Download xml`)
export const setFilterConfigByType = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] set filter config by type`,
    props<{ filterType: FilterType; items?: FilterItem[] }>(),
  )
export const setFilterValuesByType = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] set filter values by type`,
    props<{ matchId: string; filterType: FilterType; values: FilterItem[] }>(),
  )
export const setFilterValues = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] set filter values`,
    props<{ matchId: string; filterValues: PartialRecord<FilterType, FilterItem[]> }>(),
  )
export const updateFilterValuesByType = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] update filter values by type`,
    props<{ filterType: FilterType; values: FilterItem[] }>(),
  )
export const updateFilterValues = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] update filter values`,
    props<{ filterValues: PartialRecord<FilterType, FilterItem[]> }>(),
  )
export const resetFilterValuesAndActive = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] reset filters values and active`)
export const applyFilters = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] apply filters`)
export const setFiltersActive = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] set filters active`,
    props<{ matchId: string; filtersActive: PartialRecord<FilterType, FilterItem[]> }>(),
  )
export const setSortFilter = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] set sort filter`,
    props<{ matchId: string; sortFilter: FilterItem }>(),
  )
export const setSelectedTagItem = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] set selected tag item`,
    props<{ selectedTagItem: TagItem }>(),
  )
export const reFetchTags = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] re fetch tags`)
export const openConfirmDeleteModal = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] open confirm delete modal`,
    props<{ displayMode: string; itemToDelete: TagItem; matchId: string }>(),
  )
export const closeConfirmDeleteModal = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] close confirm delete modal`)
export const acceptConfirmDeleteModal = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] accept confirm delete modal`,
    props<{ itemToDelete: TagItem; matchId: string }>(),
  )
export const setIsDeletingTagItem = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] set is deleting tag item`,
    props<{ isDeletingTagItem: boolean }>(),
  )
