import { ContentType, DefaultClasses, ITableConfig } from '@shared/components/table/table.model'

export const TEAM_LAST_MATCHES_TABLE_CONFIG: ITableConfig[] = [
  {
    header: 'MATCHES_RESULTS_LAB_MATCH',
    type: ContentType.Template,
    template: null,
    extraClass: 'u-6/12@lg u-4/12@xl',
  },
  {
    header: 'MATCHES_RESULTS_LAB_COMPETITION',
    type: ContentType.Template,
    template: null,
    extraClass: 'u-2/12@lg u-3/12@xl',
  },
  {
    header: 'MATCHES_RESULTS_LAB_MATCHDAY',
    type: ContentType.Template,
    template: null,
    defaultClass: DefaultClasses.CellSmall,
    extraClass: 'u-2/12@lg',
  },
  {
    header: 'MATCHES_RESULTS_LAB_DATE',
    type: ContentType.Template,
    template: null,
    defaultClass: DefaultClasses.CellSmall,
    extraClass: 'u-2/12@lg u-3/12@xl',
  },
]
