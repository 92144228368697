export enum TagType {
  User = 1,
  Event = 2,
}

export enum TagVideoType {
  undefined = -1,
  Pan = 0,
  Tv = 1,
  Tac = 2,
  Live = 5,
  Error = 99,
}

export enum TagDimensionType {
  Quick = 'quick',
  Team = 'team',
  Player = 'player',
}

export enum FilterType {
  All = 'all',
  Other = 'other',
  Team = 'team',
  Player = 'player',
  Event = 'event',
}
