import { ChangeDetectionStrategy, Component } from '@angular/core'
import { DialogConfig, DialogRef, TagItem } from '@mediacoach/ui'

@Component({
  selector: 'mcp-dialog-update-playlist-clip-comment',
  templateUrl: './dialog-update-playlist-clip-comment.component.html',
  styleUrls: ['./dialog-update-playlist-clip-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogUpdatePlaylistClipCommentComponent {
  playlistClip: TagItem

  constructor(
    private readonly _dialogConfig: DialogConfig,
    private readonly _dialogRef: DialogRef,
  ) {
    this.playlistClip = _dialogConfig.data
  }

  onCommentChange(comment: string) {
    this.playlistClip = { ...this.playlistClip, comment }
  }

  savePlaylistClip() {
    this._dialogRef.close(this.playlistClip)
  }
}
