import {
  PlayerSpaceCareer,
  PlayerSpaceTrajectory,
  PlayerSpaceTrajectoryTab,
} from '@features/player-space/models/player-space.models'
import { uuidv4 } from '@core/utils/object.utils'
import { WidgetRow } from '@widgets/models/widget.models'

export const buildPlayerSpaceNavUrl = (
  playerId: string,
  teamId: string,
  seasonId: string,
  competitionId: string,
): string =>
  `/players/${playerId}/teams/${teamId}/competitions/${competitionId}/seasons/${seasonId}`

const parseTrajectoryRow = (
  trajectory: PlayerSpaceTrajectory,
  hideText: boolean,
  styleClass: string,
  expandableKey?: string,
): WidgetRow<PlayerSpaceTrajectory> => ({
  ...trajectory,
  id: uuidv4(),
  name: trajectory?.team || trajectory?.name,
  hideText,
  expandableKey,
  styleClass,
})

export const buildPlayerSpaceTrajectoryTab = (
  playerCareer: PlayerSpaceCareer,
): PlayerSpaceTrajectoryTab[] => {
  return [
    {
      mode: 'clubs',
      label: 'MTR_WIDGET_TRAJECTORY_CLUBS',
      content: playerCareer.clubs.map((t) => ({
        ...parseTrajectoryRow(t, false, 'parent-row', 'competitions'),
        competitions: t.competitions.map((c) => parseTrajectoryRow(c, true, 'child-row')),
      })),
    },
    {
      mode: 'national',
      label: 'MTR_WIDGET_TRAJECTORY_NATIONAL',
      content: playerCareer.nationalTeams.map((t) => ({
        ...parseTrajectoryRow(t, false, 'parent-row', 'competitions'),
        competitions: t.competitions.map((c) => parseTrajectoryRow(c, true, 'child-row')),
      })),
    },
  ]
}
