import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import {
  DEFAULT_IMAGE_BY_DIMENSION_TYPE,
  MenuItem,
  MenuItemEvent,
  TagDimensionType,
  TagItem,
} from '@mediacoach/ui'
import { PLACEHOLDER_IMAGES } from '@mediacoach-ui-library/global'
import { PlaylistContextMenuAction } from '@core/enums/playlist-context-menu-action.enum'

@Component({
  selector: 'mcp-playlist-item-info',
  templateUrl: './playlist-item-info.component.html',
  styleUrls: ['./playlist-item-info.component.theme.scss', './playlist-item-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaylistItemInfoComponent {
  @Input() item: TagItem
  @Input({ required: true }) lang: string
  @Input() isModal: boolean

  @Output() menuClick = new EventEmitter<MenuItemEvent>()

  readonly placeholder = PLACEHOLDER_IMAGES
  readonly playlistItemInfoMenuItems: MenuItem[] = [
    {
      id: PlaylistContextMenuAction.editComment,
      label: 'MTR_PLAYLIST_OPTIONS_EDIT_COMMENT',
    },
    {
      id: PlaylistContextMenuAction.shareClip,
      label: 'MTR_PLAYLIST_OPTIONS_SHARE_CLIP',
      disabled: true,
    },
    {
      id: PlaylistContextMenuAction.markAsFavorite,
      label: 'MTR_PLAYLIST_OPTIONS_MARK_AS_FAVORITE',
      disabled: true,
    },
    {
      id: PlaylistContextMenuAction.deleteClipFromPlaylist,
      label: 'MTR_PLAYLIST_OPTIONS_DELETE_CLIP_FROM_PLAYLIST',
    },
  ]

  get isEntity() {
    return [TagDimensionType.Team, TagDimensionType.Player].includes(this.item?.dimensionType)
  }

  get placeholderImage() {
    return DEFAULT_IMAGE_BY_DIMENSION_TYPE[this.item?.dimensionType]
  }
}
