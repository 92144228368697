import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core'
import { DetailsWidgetBase } from '@core/models/base-classes/details-widget.base'
import { Column, GridFooter } from '@shared/components/grid/models/grid.models'
import { Loadable } from '@core/models/models/loadable.models'

@Component({
  selector: 'mcp-widget-grid-overview',
  templateUrl: './widget-grid-overview.component.html',
  styleUrls: ['./widget-grid-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetGridOverviewComponent extends DetailsWidgetBase {
  @Input()
  set data(_s: Loadable<any[]>) {
    this.teamLoading = _s.loading
    if (_s && _s.value && !_s.loading) {
      this.stickyFooter = this.defaultStickyFooterKeys.reduce(
        (rData, key) => ({
          ...rData,
          [key]: this._mapFooter(this.expandedColumns || []),
        }),
        {},
      )
      this.overview =
        (this.mapOverviewFn && _s.value.map((m) => this.mapOverviewFn(m, this.stickyFooter))) ||
        _s.value
    }
  }

  @Input() lang: string
  @Input() pdfLoader = false
  @Input() label: string
  @Input() totalTitle: string
  @Input() compactColumns: Column[]
  @Input() expandedColumns: Column[]
  @Input() firstColumBlock: TemplateRef<unknown>
  @Input() toggle: TemplateRef<unknown>
  @Input() mapOverviewFn: (data, stickyFooter) => any
  @Input() defaultStickyFooterKeys = ['metrics']
  @Input() metricKey = 'metrics'

  @Output() pdfExport = new EventEmitter<string>()
  @Output() cellClick: EventEmitter<any> = new EventEmitter()

  stickyFooter: any = {}
  maximized: boolean
  overview: any[]

  constructor() {
    super()
  }

  maximize(): void {
    this.maximized = true
  }

  exportToPdf(): void {
    this.pdfExport.emit('app-dialog mcp-grid.expanded #mcp-grid')
  }

  private _mapFooter(columns: Column[]): { [key: string]: GridFooter } {
    return columns.reduce(
      (footer, column) => ({
        ...footer,
        [column.key]: {
          value: 0,
          unit: undefined,
          icon: column.icon,
        } as GridFooter,
      }),
      {} as { [key: string]: GridFooter },
    )
  }
}
