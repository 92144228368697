import { ParseStatics, StaticOrientation, TEAMS, TeamType } from '@mediacoach-ui-library/global'
import { AssetMatch, Match, MatchLineup, MatchTeam } from '@core/models/dto/match.dto'
import { McpTeamInfo } from '@core/models/dto/team.dto'
import { Season } from '@core/models/dto/season.dto'
import { getCurrentPlayer } from '@core/utils/player.utils'
import { getTeamType } from '@features/match-tabs/containers/match-player/utils/match-player.utils'
import { McpPlayer } from '@core/models/dto/player.dto'
import { MatchesQuery } from '@features/matches/models/match-list.models'

export const findPlayerTeamId = (playerId: string, match: Match): string => {
  for (let i = 0; i < TEAMS.length; i++) {
    const team = match[TEAMS[i]].team
    const lineup = team.squad && team.squad.length > 0 ? team.squad : team.lineup
    for (let j = 0; j < lineup.length; j++) {
      if (lineup[j].id === playerId) {
        return team.id
      }
    }
  }
}

const mapComparablePlayerRequest = (
  player: McpPlayer,
  match: AssetMatch,
  addCompetition: boolean,
) => ({
  id: player.id,
  teamId: findPlayerTeamId(player.id, match),
  ...(addCompetition ? { competitionId: match.competitionId, seasonId: match.seasonId } : {}),
})

export const mapComparisonRequest = (
  playerA,
  playerB,
  playerPosition,
  match,
  addCompetition = false,
) => ({
  playerA: mapComparablePlayerRequest(playerA, match, addCompetition),
  playerB: mapComparablePlayerRequest(playerB, match, addCompetition),
  playerPosition,
  matchId: match.id,
})

export const getTeamImageFromAssetMatch = ({ team }: MatchTeam): string =>
  (
    team.parsedImages['Portrait'] as {
      Small2x: string
    }
  ).Small2x

export const parseSelectedMatch = (
  iterableMatch: Match,
  seasons: Season[],
  currentMatch: Match,
) => {
  const season = seasons.find((s) => s.id === iterableMatch.seasonId)
  const competition = season.competitions.find((c) => c.id === iterableMatch.competitionId)
  const _match = currentMatch?.id === iterableMatch.id ? currentMatch : iterableMatch
  return {
    ..._match,
    season,
    competition,
  }
}

export const mapPlayerSummary = (player, playerPosition?) => ({
  ...player,
  summary: {
    metricPlayerName: `${player.shirtNumber}. ${player.formattedName}`,
    ...player,
    ...ParseStatics(player.statics, [StaticOrientation.Portrait]),
  },
  playerPosition: playerPosition || player.playerPosition,
})

export const mapPlayer = (player, team, match, playerPosition) => ({
  ...ParseStatics(player.statics, [StaticOrientation.Portrait]),
  ...getCurrentPlayer(player, playerPosition),
  metricPlayerName: `${player.shirtNumber}. ${player.formattedName}`,
  team: {
    ...team,
    image: (team.parsedImages.Portrait as { Small2x: string }).Small2x,
  },
  teamType: getTeamType(match, team),
})

export const mapTeamSquad = (match): Record<TeamType, { values: MatchLineup[] }> =>
  TEAMS.reduce(
    (obj, key) => ({
      ...obj,
      [key]: { values: (match[`${key}Team`].squad || {}).players },
    }),
    {} as Record<TeamType, { values: MatchLineup[] }>,
  )

export const findTeamType = (teamId: string, match: Match): TeamType =>
  match.home.team.id === teamId ? TeamType.Home : TeamType.Away

export const filterByCompetition = (list: Match[] = [], query: MatchesQuery) =>
  query && list
    ? list.filter(
        (match) => match.competitionId === query.competition && match.seasonId === query.season,
      )
    : list

export const sortByMatchdayDescDateDesc = (items: Match[]) =>
  [...items].sort((a, b) => {
    if (a.matchdayNumber !== b.matchdayNumber) {
      return -(a.matchdayNumber - b.matchdayNumber)
    }

    if (a.schedule.translated.date !== b.schedule.translated.date) {
      return -(a.schedule.translated.date.valueOf() - b.schedule.translated.date.valueOf())
    }
  })

const updateScoreOf = (team: 'home' | 'away', oldMatch, newMatch): McpTeamInfo | any => ({
  team: oldMatch[team].team,
  score: {
    ...oldMatch[team].score,
    standardTimeScore: newMatch[team].score.standardTimeScore,
  },
})

export const updateMatchScores = (match: Match, liveMatch: Match): Match => ({
  ...match,
  home: updateScoreOf('home', match, liveMatch),
  away: updateScoreOf('away', match, liveMatch),
  state: liveMatch.state,
})

export const updateWithLiveMatches = (match: Match, liveMatches: Match[] = []): Match => {
  const updatedMatch = liveMatches.find((m) => m.id === match.id)
  return updatedMatch ? updateMatchScores(match, updatedMatch) : match
}
