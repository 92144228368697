import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { Loadable } from '@core/models/models/loadable.models'
import { Match } from '@core/models/dto/match.dto'
import { TEAM_LAST_MATCHES_TABLE_CONFIG } from './constants/widget-last-matches.constants'
import { ITableConfig } from '@shared/components/table/table.model'
import { DetailsWidgetBase } from '@core/models/base-classes/details-widget.base'
import { DEFAULT_ROW_TEMPLATES } from '@shared/components/match-item/constants/match-item.constants'
import { MatchItemTemplateConfig } from '@shared/components/match-item/models/match-item.model'
import { Competition } from '@mediacoach-ui-library/global'

@Component({
  selector: 'mcp-widget-last-matches',
  templateUrl: './widget-last-matches.component.html',
  styleUrls: ['./widget-last-matches.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetLastMatchesComponent extends DetailsWidgetBase {
  @Input() tableConfig: ITableConfig[] = TEAM_LAST_MATCHES_TABLE_CONFIG
  @Input() matchItemConfig: MatchItemTemplateConfig[] = DEFAULT_ROW_TEMPLATES
  @Input() override competition: Competition

  @Input() set matches(_m: Loadable<Match[]>) {
    this.teamLoading = _m.loading
    if (_m && _m.value) {
      this.lastMatches = _m.value
    }
  }

  @Output() matchesSelected = new EventEmitter<Match[]>()

  lastMatches: Match[]

  toggle(match): void {
    match.selected = !match.selected
  }
}
