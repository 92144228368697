import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core'
import { Competition, Season } from '@core/models/dto/season.dto'
import { PLACEHOLDER_IMAGES } from '@mediacoach-ui-library/global'
import { debounce } from '@mediacoach/ui'
import { scrollByElementList } from '@core/utils/dom.utils'

@Component({
  selector: 'mcp-season-competition-selector',
  templateUrl: './season-competition-selector.component.html',
  styleUrl: './season-competition-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeasonCompetitionSelectorComponent implements AfterContentInit {
  private _seasons: Season[]
  private _seasonId: string
  private _competitionId: string
  readonly competitionPlaceholder: string = PLACEHOLDER_IMAGES.COMPETITION
  selectedSeason: Season
  selectedCompetition: Competition

  @Input() set seasonId(_seasonId: string) {
    this._seasonId = _seasonId
    if (this.seasons.length) {
      this.selectedSeason = this.seasons.find(({ id }) => id === this.seasonId) || this.seasons[0]
    }
  }
  get seasonId() {
    return this._seasonId
  }
  @Input() set competitionId(_competitionId: string) {
    this._competitionId = _competitionId
    if (this.selectedSeason) {
      this.selectedCompetition =
        this.selectedSeason.competitions.find(({ id }) => id === this.competitionId) ||
        this.selectedSeason.competitions[0]
    }
  }
  get competitionId() {
    return this._competitionId
  }

  @Input() set seasons(_seasons: Season[]) {
    this._seasons = _seasons
  }

  get seasons() {
    return this._seasons
  }

  @Input() hideSeasons = false

  @Output() seasonChange: EventEmitter<Season> = new EventEmitter<Season>()
  @Output() competitionChange: EventEmitter<Competition> = new EventEmitter<Competition>()

  @ViewChildren('seasonElement') seasonElements: QueryList<ElementRef>
  @ViewChildren('competitionElement') competitionElements: QueryList<ElementRef>

  ngAfterContentInit() {
    this._autoScrollToActiveItems()
  }

  seasonChangeData(season: Season) {
    this.selectedSeason = season
    this.seasonChange.emit(season)
    if (
      this.selectedCompetition &&
      !this.selectedSeason.competitions.find(({ id }) => id === this.selectedCompetition.id)
    ) {
      this.selectedCompetition = this.selectedSeason.competitions[0]
      this.competitionChange.emit(this.selectedCompetition)
    }
  }

  @debounce(0)
  private _autoScrollToActiveItems() {
    scrollByElementList(this.seasonElements, 'mcp-widget-dialog__item--active')
    scrollByElementList(this.competitionElements, 'mcp-widget-dialog__item--active')
  }
}
