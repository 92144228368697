<div class="c-field-diagram__container">
  <div *ngIf="title" class="c-field-diagram__title">
    <app-title [title]="title"></app-title>
  </div>
  <app-segmented-control
    [options]="options"
    [modifiers]="[SegmentModifier.SecondLevel]"
    [value]="segmentValue"
    [disabled]="!(selectedPlayer$ | async)"
    (onChange)="onChange.emit($event)"
  >
  </app-segmented-control>
  <div class="c-field-diagram__field" [class.c-field-diagram__field--empty]="!data?.values?.length">
    <app-loader [loading]="loading">
      <mc-field-diagram
        [type]="type"
        [showPopUp]="showPopUp"
        [fieldDiagramData]="data"
        [forceFromLeft]="true"
        [keepPlayerSelected]="keepPlayerSelected"
        [selectedPlayerNumber]="selectedPlayerNumber"
        [resetWhenClickOutside]="resetWhenClickOutside"
        [arrowDirection]="arrowDirection"
        [isColored]="isColored"
        [playerPopUpTemplate]="playerPopUpTemplate"
        [connectionCoordinateType]="connectionCoordinateType"
        [modifiers]="[FieldDiagramModifier.Gradient, FieldDiagramModifier.FullOpacity]"
        (onPlayerChange)="onPlayerChange.emit($event)"
      >
      </mc-field-diagram>
    </app-loader>
  </div>
  <ng-container *ngIf="connectionCoordinateChange$ | async"></ng-container>
</div>
