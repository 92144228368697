<app-loader [loading]="loading || teamLoading">
  <div [attr.data-cy]="'widget-metrics'" class="widget-team-metrics">
    <app-title
      [title]="'COMMONS_TITLE_GENERAL_DATA_FOR_COMPETITION'"
      [titleParams]="{ competition: competition?.officialName || '' }"
      [size]="titleSize"
    ></app-title>

    <div class="box-wrapper">
      <mc-box [hasDecoration]="false" [class.scrollable]="!isNotDesktop" [modifers]="boxModifiers">
        <ng-container
          *ngIf="
            !chartSetup?.isSpiderChartEmpty || !listSetup?.isMetricListEmpty;
            else notAvailableContent
          "
        >
          <mc-spider-chart
            [data]="chartSetup?.spiderChart"
            [header]="chartSetup?.spiderChartHeader"
            (onClickMetric)="onMetricClick($event)"
          >
            <ng-template *ngIf="isPlayer" mcTemplate="header">
              <div class="widget-team-metrics__position-btn">
                <span (click)="changePlayerPosition()" class="widget-team-metrics__position-text">
                  {{ playerPosition | translate }}
                  <i *ngIf="!teamLoading && !isGoalkeeper" class="u-icon u-icon--loop"></i>
                </span>
              </div>
            </ng-template>
          </mc-spider-chart>

          <mc-list
            *ngIf="!listSetup?.isMetricListEmpty"
            [data]="listSetup?.metricList"
            [showTeamMarks]="true"
            [isCollapsible]="isNotDesktop"
            [itemTemplate]="metricItem"
          >
            <ng-template #metricItem let-item>
              <mcui-metric-item
                [title]="item.text | translate"
                [leftValue]="item.homeValue"
                [leftUnit]="item.homeUnit | translate"
                [rightValue]="item.awayValue"
                [rightUnit]="item.awayUnit | translate"
              />
            </ng-template>
          </mc-list>
        </ng-container>
        <ng-template #notAvailableContent>
          <mcp-unavailable-content [competition]="competition"></mcp-unavailable-content>
        </ng-template>
      </mc-box>
    </div>
  </div>
</app-loader>
