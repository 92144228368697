import { TabType } from '@features/matches/enums/matches.enum'
import { SegmentOption } from '@shared/components/segmented-control/segmented-control.models'

export const TABS: SegmentOption[] = [
  {
    id: TabType.Alignment,
    label: 'MATCHDETAIL_TABS_ALIGNMENT',
  },
  {
    id: TabType.Data,
    label: 'MATCHDETAIL_TABS_DATA',
  },
  {
    id: TabType.PlayList,
    label: 'MATCHDETAIL_TABS_PLAYLIST',
  },
]
