<app-dialog
  [mcpAppendTo]="ref"
  [inlineWrapperSelector]="$any(ref)"
  class="c-player-comparison"
  styleClass="c-dialog--attached"
  [keepUnderToolbar]="!!ref"
  (showEvent)="onShow()"
>
  <div class="dialog-header c-player-comparison__dialog--header-title">
    <span> {{ 'MTR_TITLE_PLAYER_VS' | translate: { player: initialPlayer?.nameAndDorsal } }}</span>
  </div>

  <div class="c-player-comparison__dialog--content">
    <app-segmented-control
      [options]="teamSegmentOptions"
      [value]="selectedTeam"
      [modifiers]="[SegmentModifier.FirstLevel, SegmentModifier.Inline]"
      (onChange)="onTeamChange($event)"
    >
    </app-segmented-control>
    <div class="c-player-comparison__dialog--content-list">
      <mc-list
        [data]="match?.playerList[selectedTeam] | excludePlayer: initialPlayer?.id"
        [itemTemplate]="player"
        (onClick)="playerSelect.emit({ playerA: initialPlayer, playerB: $event })"
      ></mc-list>
    </div>
  </div>
</app-dialog>
<ng-template #player let-item>
  <mcp-player-list-item
    [data]="item"
    extraClasses="mcp-player-list-item__hover"
    [isNavigationActive]="true"
  >
  </mcp-player-list-item>
</ng-template>
