<div class="c-heat-map__container">
  <div *ngIf="title" class="c-heat-map__title">
    <app-title [title]="title"></app-title>
  </div>
  <app-segmented-control
    [options]="options"
    [modifiers]="[SegmentModifier.SecondLevel]"
    [value]="segmentValue"
    (onChange)="segmentValue = $event"
  >
  </app-segmented-control>
  <div
    class="c-heat-map__content"
    [class.c-heat-map__content--empty]="!(dataDictionary[segmentValue] || []).length"
  >
    <app-loader [loading]="loading">
      <mc-heat-map
        [heatMapData]="dataDictionary[segmentValue] || []"
        [arrowDirection]="arrowDirection"
        [heatMapConfig]="heatMapConfig"
        [isColored]="isColored"
      >
      </mc-heat-map>
    </app-loader>
  </div>
</div>
