export enum PlaylistContextMenuAction {
  editComment = 'menuEntryEditComment',
  shareClip = 'menuEntryShareClip',
  markAsFavorite = 'menuEntryMarkAsFavorite',
  deleteClipFromPlaylist = 'menuEntryDeleteClipFromPlaylist',
  newPlaylist = 'menuEntryNewPlaylist',
  managePlaylist = 'menuEntryManagePlaylist',
  shareFilteredClips = 'menuEntryShareFilteredClips',
  shareAllClips = 'menuEntryShareAllClips',
  exportXml = 'menuEntryExportXml',
}
