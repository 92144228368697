import { SegmentOption } from '../segmented-control/segmented-control.models'

export const ParseHeatMapData = (data): number[] =>
  data ? data.split('').map((el) => parseFloat(el)) : []

export const HeatMapDefaultOptions: SegmentOption[] = [
  {
    id: 'global',
    label: 'MTR_HEAT_MAP_GLOBAL',
  },
  {
    id: 'offensive',
    label: 'MTR_HEAT_MAP_OFFENSIVE',
  },
  {
    id: 'defensive',
    label: 'MTR_HEAT_MAP_DEFENSIVE',
  },
]
