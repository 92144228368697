<section
  *mcuiLoading="loading || externalLoading; overlay: true"
  [attr.data-cy]="'item-selector'"
  class="item-selector"
  [class.item-selector--players]="isPlayer"
>
  <app-carousel
    [items]="list || []"
    [loop]="false"
    [boundToEdge]="true"
    [enableForceSelect]="enableForceSelect"
    [disableSelect]="disableSelect"
    [selected]="selectedIndex"
    (onSelect)="selectItem.emit($event)"
  >
    <ng-template
      appTemplate="item"
      let-item
      let-isSelected="isSelected"
      let-disableSelect="disableSelect"
    >
      <div
        class="item-selector__item"
        [class.is-player]="isPlayer"
        [class.has-divider]="item.hasDivider"
        [class.is-selected]="isSelected"
        [class.is-disabled]="disableSelect"
      >
        <div
          class="item-selector__item-logo"
          [mcpBackgroundImage]="item?.portraitLogo || imagePlaceholder"
          tooltipPosition="bottom"
          [mcuiTooltip]="isPlayer ? item?.metricPlayerName ?? item?.formattedName : item?.name"
        ></div>
      </div>
    </ng-template>
    <ng-template appTemplate="no-items">
      <p class="item-selector__no-items" *ngIf="!isNotAvailableContent; else notAvailableContent">
        {{ 'P_COMMONS_HEADER_LAB_' + (isPlayer ? 'PLAYERS' : 'TEAMS') + '_NO_ITEMS' | translate }}
      </p>
    </ng-template>
  </app-carousel>
</section>
<ng-template #notAvailableContent>
  <mcp-unavailable-content [competition]="competition"></mcp-unavailable-content>
</ng-template>
