import { createReducer, on } from '@ngrx/store'
import {
  setComparisonLoader,
  setExportMetricsToPDFLoader,
  setIsPlaylistTabSelected,
  setMatch,
  setMatchAggregationMode,
  setMatchHeatMap,
  setMatchHeatMapLoader,
  setMatchLoader,
  setMatchMarkers,
  setMatchPassMatrix,
  setMatchPassMatrixLoader,
  setMatchPlayerAggregationMode,
  setMatchPlayerComparison,
  setMatchPlayerMetricsLoader,
  setMatchSelectedPlayer,
  setMatchSelectedPlayerLoader,
  setMatchStream,
  setMatchTeamMetrics,
  setMatchTeamMetricsLoader,
  setMetricsLoader,
  setStreamType,
  setTimelineConfig,
  setTimelineLoader,
  wipeStreamData,
} from '@core/state/actions/stream-match.merged-actions'
import { MergedTokens } from '@core/state/models/merged-tokens.model'
import { initialStreamState, StreamState } from '@core/state/models/stream.state'
import { setStreamPlayerMetrics } from '@core/state/actions/stream.actions'
import { SafeObjectData } from '@mediacoach-ui-library/global'
import {
  setCodes,
  setDimensions,
  setFilterConfigByType,
  setFiltersActive,
  setFilterValues,
  setFilterValuesByType,
  setIsDeletingTagItem,
  setParsedTagItems,
  setPlaylistLoader,
  setPlaylists,
  setSelectedPlaylist,
  setSelectedTagItem,
  setSortFilter,
  setTags,
} from '@core/state/actions/stream-playlist.merged-actions'

export const initialCommonState: StreamState = { ...initialStreamState }

export const commonReducer = (identifier: MergedTokens) =>
  createReducer(
    initialCommonState,
    on(setMatch(identifier), (state, { currentMatch }) => ({ ...state, currentMatch })),
    on(setMatchLoader(identifier), (state, { matchLoader }) => ({ ...state, matchLoader })),
    on(setMetricsLoader(identifier), (state, { metricsLoader }) => ({ ...state, metricsLoader })),
    on(setStreamPlayerMetrics, (state, { playerMetrics }) => ({ ...state, playerMetrics })),
    on(setMatchPlayerMetricsLoader(identifier), (state, { playerMetricsLoader }) => ({
      ...state,
      playerMetricsLoader,
    })),
    on(setMatchTeamMetrics(identifier), (state, { teamMetrics }) => ({ ...state, teamMetrics })),
    on(setMatchTeamMetricsLoader(identifier), (state, { teamMetricsLoader }) => ({
      ...state,
      teamMetricsLoader,
    })),
    on(setMatchPassMatrix(identifier), (state, { passMatrix }) => ({ ...state, passMatrix })),
    on(setMatchPassMatrixLoader(identifier), (state, { passMatrixLoader }) => ({
      ...state,
      passMatrixLoader,
    })),
    on(setMatchHeatMap(identifier), (state, { heatMap }) => ({ ...state, heatMap })),
    on(setMatchHeatMapLoader(identifier), (state, { heatMapLoader }) => ({
      ...state,
      heatMapLoader,
    })),
    on(setTimelineConfig(identifier), (state, { timelineConfig }) => ({
      ...state,
      timelineConfig,
    })),
    on(setTimelineLoader(identifier), (state, { timelineLoader }) => ({
      ...state,
      timelineLoader,
    })),
    on(setMatchMarkers(identifier), (state, { markers }) => ({ ...state, markers })),
    on(setMatchAggregationMode(identifier), (state, { aggregationMode }) => ({
      ...state,
      aggregationMode,
    })),
    on(setMatchSelectedPlayer(identifier), (state, { selectedPlayer }) => ({
      ...state,
      selectedPlayer,
    })),
    on(setMatchSelectedPlayerLoader(identifier), (state, { playerLoader }) => ({
      ...state,
      playerLoader,
    })),
    on(setMatchPlayerComparison(identifier), (state, { comparison }) => ({ ...state, comparison })),
    on(setComparisonLoader(identifier), (state, { comparisonLoader }) => ({
      ...state,
      comparisonLoader,
    })),
    on(setMatchPlayerAggregationMode(identifier), (state, { aggregationMode, isVsEnabled }) => ({
      ...state,
      ...(isVsEnabled
        ? { comparison: { ...state.comparison, aggregationMode } }
        : { selectedPlayer: { ...state.selectedPlayer, aggregationMode } }),
    })),
    on(setMatchStream(identifier), (state, { stream }) => ({ ...state, stream })),
    on(setStreamType(identifier), (state, { streamType }) => ({ ...state, streamType })),
    on(setExportMetricsToPDFLoader(identifier), (state, { pdfLoader }) => ({
      ...state,
      pdfLoader,
    })),
    on(setIsPlaylistTabSelected(identifier), (state, { isPlaylistTabSelected }) => ({
      ...state,
      isPlaylistTabSelected,
    })),
    on(setTags(identifier), (state, { tags }) => ({
      ...state,
      playlist: { ...state.playlist, tags },
    })),
    on(setPlaylists(identifier), (state, { playlists }) => ({
      ...state,
      playlist: { ...state.playlist, playlists },
    })),
    on(setParsedTagItems(identifier), (state, { parsedTagItems }) => ({
      ...state,
      playlist: { ...state.playlist, parsedTagItems },
    })),
    on(setDimensions(identifier), (state, { dimensions }) => ({
      ...state,
      playlist: { ...state.playlist, dimensions },
    })),
    on(setCodes(identifier), (state, { codes }) => ({
      ...state,
      playlist: { ...state.playlist, codes },
    })),
    on(setPlaylistLoader(identifier), (state, { playlistLoader }) => ({
      ...state,
      playlist: { ...state.playlist, playlistLoader },
    })),
    on(setFilterConfigByType(identifier), (state, { filterType, items }) => ({
      ...state,
      playlist: {
        ...state.playlist,
        filterConfig: {
          ...SafeObjectData(state.playlist, 'filterConfig', {}),
          [filterType]: items,
        },
      },
    })),
    on(setFilterValuesByType(identifier), (state, { matchId, filterType, values }) => ({
      ...state,
      playlist: {
        ...state.playlist,
        filterValues: {
          ...SafeObjectData(state.playlist, 'filterValues', {}),
          [matchId]: {
            ...SafeObjectData(state.playlist.filterValues, matchId, {}),
            [filterType]: values,
          },
        },
      },
    })),
    on(setFilterValues(identifier), (state, { matchId, filterValues }) => ({
      ...state,
      playlist: {
        ...state.playlist,
        filterValues: {
          ...SafeObjectData(state.playlist, 'filterValues', {}),
          [matchId]: filterValues,
        },
      },
    })),
    on(setFiltersActive(identifier), (state, { matchId, filtersActive }) => ({
      ...state,
      playlist: {
        ...state.playlist,
        filtersActive: {
          ...SafeObjectData(state.playlist, 'filtersActive', {}),
          [matchId]: filtersActive,
        },
      },
    })),
    on(setSelectedPlaylist(identifier), (state, { matchId, selectedPlaylist }) => ({
      ...state,
      playlist: {
        ...state.playlist,
        selectedPlaylist: {
          ...SafeObjectData(state.playlist, 'selectedPlaylist', {}),
          [matchId]: selectedPlaylist,
        },
      },
    })),
    on(setSortFilter(identifier), (state, { matchId, sortFilter }) => ({
      ...state,
      playlist: {
        ...state.playlist,
        sortFilter: {
          ...SafeObjectData(state.playlist, 'sortFilter', {}),
          [matchId]: sortFilter,
        },
      },
    })),
    on(setSelectedTagItem(identifier), (state, { selectedTagItem }) => ({
      ...state,
      playlist: { ...state.playlist, selectedTagItem },
    })),
    on(setIsDeletingTagItem(identifier), (state, { isDeletingTagItem }) => ({
      ...state,
      playlist: { ...state.playlist, isDeletingTagItem },
    })),
    on(wipeStreamData(identifier), (state) => ({ ...state, ...initialCommonState })),
  )
