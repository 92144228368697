export interface SegmentOption {
  id: string
  icon?: string
  image?: string
  label?: string
  selected?: boolean
  disabled?: boolean
  extraClasses?: string
  qaId?: string
  attributes?: { [key: string]: any }
}

export enum SegmentModifier {
  FirstLevel = 'c-first-level',
  SecondLevel = 'c-second-level',
  Header = 'c-header',
  Inline = 'c-inline',
  Circled = 'c-circled',
}
