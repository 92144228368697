import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { DEFAULT_IMAGE_BY_DIMENSION_TYPE, TagDimensionType, TagItem } from '@mediacoach/ui'
import { PLACEHOLDER_IMAGES } from '@mediacoach-ui-library/global'

@Component({
  selector: 'mcp-playlist-item-info',
  templateUrl: './playlist-item-info.component.html',
  styleUrls: ['./playlist-item-info.component.theme.scss', './playlist-item-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaylistItemInfoComponent {
  @Input() item: TagItem
  @Output() deleteTagItem: EventEmitter<TagItem> = new EventEmitter<TagItem>()

  readonly placeholder = PLACEHOLDER_IMAGES

  get isEntity() {
    return [TagDimensionType.Team, TagDimensionType.Player].includes(this.item?.dimensionType)
  }

  get placeholderImage() {
    return DEFAULT_IMAGE_BY_DIMENSION_TYPE[this.item?.dimensionType]
  }
}
