import { environment } from '@env'
import * as moment from 'moment'
import { FormFieldName } from '../enum/profile-dialog.enum'

export const LANGUAGES = environment.I18N_LANGS.map(({ locale, i18nLabel }) => ({
  value: locale,
  label: i18nLabel,
}))

export const APP_DATA = {
  appVersion: environment.appVersion,
  appBuild: environment.appBuild,
  appRelease: moment(environment.appRelease, 'DDMMYYYY.hhmm').format('DD-MM-YYYY'),
}

export const TEXT_REGEX = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s-]*$/
export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
export const MAX_LENGTH_FORM = 100

export const VALIDATION_MESSAGES = {
  [FormFieldName.Name]: {
    required: 'COMMONS_PROFILE_LAB_NAME_REQUIRED',
    maxlength: 'DEMO_FORM_LAB_NAME_MAXLENGTH',
    pattern: 'COMMONS_PROFILE_PATTERN',
  },
  [FormFieldName.SurName]: {
    required: 'COMMONS_PROFILE_LAB_SURNAME_REQUIRED',
    maxlength: 'COMMONS_PROFILE_LAB_MAXLENGTH',
    pattern: 'COMMONS_PROFILE_PATTERN',
  },
  [FormFieldName.Email]: {
    required: 'COMMONS_PROFILE_LAB_EMAIL_REQUIRED',
    maxlength: 'COMMONS_PROFILE_LAB_MAXLENGTH',
    email: 'COMMONS_PROFILE_EMAIL_PATTERN',
  },
}

export const FORM_FIELDS = [
  {
    name: FormFieldName.Name,
    label: 'P_COMMONS_PROFILE_LAB_NAME',
    type: 'text',
  },
  {
    name: FormFieldName.SurName,
    label: 'P_COMMONS_PROFILE_LAB_SURNAME',
    type: 'text',
  },
  {
    name: FormFieldName.Email,
    label: 'P_COMMONS_PROFILE_LAB_EMAIL',
    type: 'text',
  },
]
