import { Directive, Self } from '@angular/core'
import { WidgetItemSelectorComponent } from '@shared/components/widget-item-selector/widget-item-selector.component'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { map } from 'rxjs/operators'
import { AnalyticsEvent } from '@core/analytics/enums/gtag-events.enum'
import { AnalyticsParam } from '@core/analytics/enums/gtag-params.enum'
import { AnalyticsCategory } from '@core/analytics/enums/gtag-categories.enum'
import { McpPlayer } from '@core/models/dto/player.dto'
import { McpTeam } from '@core/models/dto/team.dto'
import { Store } from '@ngrx/store'
import { analyticsTrackEvent } from '@core/analytics/state/actions/analytics.actions'

@UntilDestroy()
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mcp-widget-item-selector',
})
export class GtagItemSelectorDirective {
  constructor(
    @Self() private readonly _itemSelector: WidgetItemSelectorComponent,
    private readonly _store: Store,
  ) {
    this._itemSelector?.selectItem
      .pipe(
        untilDestroyed(this),
        map((item: McpPlayer | McpTeam) =>
          this._itemSelector?.isPlayer
            ? {
                eventName: AnalyticsEvent.clickPlayer,
                eventParams: {
                  [AnalyticsParam.playerId]: item.id,
                  [AnalyticsParam.teamId]: (item as McpPlayer).teamId,
                },
              }
            : {
                eventName: AnalyticsEvent.changeTeam,
                eventParams: {
                  [AnalyticsParam.teamId]: item.id,
                  [AnalyticsParam.teamName]: (item as McpTeam).shortName,
                },
              },
        ),
      )
      .subscribe(({ eventName, eventParams }) => {
        this._store.dispatch(
          analyticsTrackEvent({
            eventName,
            eventParams: {
              [AnalyticsParam.category]: AnalyticsCategory.navigation,
              ...eventParams,
            },
          }),
        )
      })

    //
  }
}
